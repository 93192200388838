import React, {useEffect, useState} from 'react';
import axios from 'axios';
import AuthService from "../../Services/auth.service";
import {toast} from "react-toastify";
import {setUserData} from "../../redux-toolkit/reducers/UserDataReducer";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {FacebookLink, GoogleLink} from "../../Utils/Constants";
import {FaFacebook, FaGoogle} from "react-icons/fa";
import {FacebookProvider, LoginButton} from 'react-facebook';

export default function FacebookButton() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function handleSuccess(codeResponse) {
        console.log(codeResponse.authResponse);
        let accessToken = codeResponse.authResponse.accessToken;
        if (accessToken.length > 0) {
            axios
                .get(`https://graph.facebook.com/me?fields=name,email,picture&access_token=${accessToken}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    //Chiamare mie api
                    console.log(res);
                    AuthService.loginSocial('facebook', JSON.stringify(res.data)).then(
                        (response) => {
                            if (response.loggedIn) {
                                toast.success("Login Success...!");
                                localStorage.setItem("token_status", JSON.stringify(true));
                                const user = AuthService.getCurrentUser().then((response) => {
                                    dispatch(setUserData(response));
                                    navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
                                });
                                // localStorage.setItem("login", JSON.stringify(true));
                            } else {
                                // console.log(response)
                                window.alert(response.message);
                            }
                        },
                        (error) => {
                            window.alert("Errore in login");
                        }
                    );
                })
                .catch((err) => console.log(err));
        }
    }

    function handleError(error) {
        console.log(error);
    }

    return (
        <FacebookProvider appId="972585207919261">
            <LoginButton className='btn btn-light'
                         scope="email"
                         onError={handleError}
                         onSuccess={handleSuccess}>
                <FaFacebook className='txt-fb'/>&nbsp;&nbsp;{FacebookLink}
            </LoginButton>
        </FacebookProvider>
    );
}