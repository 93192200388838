import {TableColumn} from "react-data-table-component";
import {CustomCellInterFace} from "../../../../Types/TableType";
import {Badges, LI, UL} from "../../../../AbstractElements";
import {Href} from "../../../../Utils/Constants";


const CustomBadge = ({position, color}: CustomCellInterFace) => {
    return (
        <Badges className="rounded-pill" color={color}>{position}</Badges>
    );
};

export interface ColonneHtmlElenco {
    id: number;
    nome: string;
    cognome: string;
    username: string;
    nascita_data: string;
    nascita_luogo: string;
    residenza_indirizzo: string;
    residenza_civico: string;
    residenza_luogo: string;
    residenza_cap: string;
    codice_fiscale: string;
    centro_id: number;
    profilo_id_ultimoutilizzo: number;
    email: string;
    data_registrazione: string;
    api_key: string;
    api_secret: string;
    iv: string;
}

export const colonneElenco: TableColumn<ColonneHtmlElenco>[] = [
    {
        name: "Id",
        selector: (row) => row.id,
        sortable: true,
    },
    {
        name: "Nome e Cognome",
        selector: (row) => row.nome + " " + row.cognome,
        sortable: true,
    },
    {
        name: "Data di nascita",
        selector: (row) => row.nascita_data,
        sortable: true,
    },
    {
        name: "Citta residenza",
        selector: (row) => row.residenza_luogo,
        sortable: true,
    },
    {
        name: "Indirizzo residenza",
        selector: (row) => row.residenza_indirizzo + " " + row.residenza_civico,
        sortable: true,
    },
    {
        name: "Ultimo profilo usato",
        selector: (row) => row.profilo_id_ultimoutilizzo,
        sortable: true,
    },
    {
        name: "Centro sportivo associato",
        selector: (row) => row.centro_id,
        sortable: true,
    },
    {
        name: "Azioni",
        sortable: true,
        cell: () => (
            <UL className="action simple-list flex-row">
                <LI className="modifica_utente">
                    <a href={Href}>
                        <i className="icon-text"/>
                    </a>
                </LI>
                <LI className="elimina_utente">
                    <a href={Href}>
                        <i className="icon-trash"/>
                    </a>
                </LI>
            </UL>
        ),
    },
];
