import React from 'react'
import SamplePageContainer from '../../../Components/Pages/SamplePage'
import CardUtenteForm from "../../../Components/Forms/Utente";

export default function Utenti() {
    return (
        <div className='page-body'>
            <CardUtenteForm/>
        </div>
    )
}
