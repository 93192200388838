import React, {ChangeEvent, Fragment, useState} from 'react'
import {Card, CardBody, Col, FormGroup, Input, Label, Row} from 'reactstrap'
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux-toolkit/store";
import SelettoreSlotOrari from "../index";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import {UseFormRegister} from "react-hook-form";
import {FormRisorsa} from "../../../../Types/FormType";

interface Props {
    orepiene: boolean;
   // onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    register: UseFormRegister<FormRisorsa>,
    formRisorsa: FormRisorsa;
    setFormRisorsa: React.Dispatch<React.SetStateAction<FormRisorsa>>;
}

export const ElencoGiorni: React.FC<Props> = ({orepiene, register, formRisorsa, setFormRisorsa}) => {
    const dayslots = useSelector((state: RootState) => state.dayslots);
    const timeslots = useSelector((state: RootState) => state.timeslots);

    const sottotitolo = [
        {
            text: "Seleziona tutti gli orari che vuoi abilitare per ogni singolo giorno della settimana, ogni orario selezionato sarà evidenziato ",
            code: " con bordo blu ",
        },
        {
            text: " che rende attiva l'impostazione. ",
        },
    ];

    return (
        <>
            {dayslots.map((data) => (
                <Card className='height-equal' key={data.id}>
                    <CommonCardHeader headClass='pb-0' title={data.giorno} subTitle={sottotitolo}/>
                    <CardBody>
                        <SelettoreSlotOrari timeslots={timeslots} orepiene={orepiene} register={register}
                                            idprefix={data.id.toString()} formRisorsa={formRisorsa} setFormRisorsa={setFormRisorsa} />
                    </CardBody>
                </Card>
            ))}
        </>
    )
}
export default ElencoGiorni;