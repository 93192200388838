import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL === undefined ? 'https://sport.artliver.it/' : process.env.REACT_APP_API_URL;


export const colonneElencoDiscipline = () => {
    return axios
        .get(API_URL + "superadmin/disciplina", {
            headers: {
                'Token': localStorage.getItem('token')
            }
        })
        .then((response) => {
            return response.data._embedded.disciplina;
        });
};