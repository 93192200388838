import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import LayoutRoutes from './LayoutRoutes'
import PrivateRoutes from './PrivateRoutes';
import SignIn from '../Auth';

interface RoutersProps {
    isLogged: boolean; // Specifica il tipo di isLogged come boolean
}

export default function Routers({isLogged}: RoutersProps) {
    // const login = localStorage.getItem("token");

    return (
        <BrowserRouter basename={'/'}>
            <Routes>
                {isLogged ? (
                    <>
                        <Route
                            path={`${process.env.PUBLIC_URL}` || '/'}
                            element={
                                <Navigate to={`${process.env.PUBLIC_URL}/pages/sample_page`}/>
                            }
                        />
                    </>
                ) : (
                    ""
                )}
                <Route path={"/"} element={<PrivateRoutes/>}>
                    <Route path={`/*`} element={<LayoutRoutes/>}/>
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/login`} element={<SignIn/>}/>
            </Routes>
        </BrowserRouter>
    )
}
