import {ColonneHtmlElencoPrenotazioni} from "../../Colonne/Superadmin/ElencoPrenotazioni";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL === undefined ? 'https://sport.artliver.it/' : process.env.REACT_APP_API_URL;


export const colonneElencoPrenotazioniData = () => {
    return axios
        .post(API_URL + "superadmin/prenotazioni", {
            headers: {
                'Token': localStorage.getItem('token')
            }
        })
        .then((response) => {
            console.log(response.data.dati);
            return response.data.dati;
        });
};

/*
export const colonneElencoPrenotazioniData: ColonneHtmlElencoPrenotazioni[] = [
    {
        id: 1,
        data_prenotazione: "27/02/2024",
        utente_prenotante: "Michele",
        risorsa: "Campo Verde S.Orso",
        importo_totale: "16,00",
        importo_pagato: "8,00",
        stato: "attivo"
    },
    {
        id: 2,
        data_prenotazione: "27/02/2024",
        utente_prenotante: "Michele",
        risorsa: "Campo Verde S.Orso",
        importo_totale: "16,00",
        importo_pagato: "8,00",
        stato: "attivo"
    },
    {
        id: 3,
        data_prenotazione: "27/02/2024",
        utente_prenotante: "Michele",
        risorsa: "Campo Verde S.Orso",
        importo_totale: "16,00",
        importo_pagato: "8,00",
        stato: "attivo"
    },
    {
        id: 4,
        data_prenotazione: "27/02/2024",
        utente_prenotante: "Michele",
        risorsa: "Campo Verde S.Orso",
        importo_totale: "16,00",
        importo_pagato: "8,00",
        stato: "attivo"
    }
]

 */