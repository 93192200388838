import React, { useState } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (nome: string, cognome: string, date: string, time: string) => void;
}

const ModaleMetronic: React.FC<ModalProps> = ({ isOpen, onClose, onSave }) => {
  // Stati per ciascun campo di input
  const [nome, setNome] = useState('');
  const [cognome, setCognome] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  // Gestisce il salvataggio dei dati inseriti
  const handleSaveClick = () => {
    onSave(nome, cognome, date, time);
    onClose(); // Chiude il modale dopo il salvataggio
  };

  if (!isOpen) return null;

  return (
    <div className={`animate__animated animate__fadeIn modal fade ${isOpen ? "show" : ""}`} style={{ display: isOpen ? "block" : "none" }} id="exampleModalSizeLg" tabIndex={-1} role="dialog" aria-labelledby="exampleModalSizeLg" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Aggiungi Prenotazione</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body col-12">
            <div className="mb-3">
              <label htmlFor="nome" className="form-label">Nome</label>
              <input type="text" className="form-control" id="nome" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
            <div className="mb-3">
              <label htmlFor="cognome" className="form-label">Cognome</label>
              <input type="text" className="form-control" id="cognome" value={cognome} onChange={(e) => setCognome(e.target.value)} />
            </div>
            <div className='row col-12'>
            <div className="mb-3 col-4">
              <label htmlFor="data" className="form-label">Data</label>
              <input type="date" className="form-control" id="data" value={date} onChange={(e) => setDate(e.target.value)} />
            </div>
            <div className="mb-3 col-4">
              <label htmlFor="time" className="form-label">Ora</label>
              <input type="time" className="form-control" id="time" value={time} onChange={(e) => setTime(e.target.value)} />
            </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>Chiudi</button>
            <button type="button" className="btn btn-primary" onClick={handleSaveClick}>Salva</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModaleMetronic;
