import {SidebarMenuType} from "../Types/LayoutTypes";

/*export const menuList: SidebarMenuType[] = [
    {
        title: 'Prenotazioni',
        menucontent: 'Gestione prenotazioni',
        menu: [
            {
                id: 1,
                title: 'Prenota',
                url: `${process.env.PUBLIC_URL}/user/prenotazioni/nuova`,
                icon: 'calendar',
                type: 'link'
            },
            {
                id: 2,
                title: 'Elenco',
                url: `${process.env.PUBLIC_URL}/user/prenotazioni/elenco`,
                icon: 'file',
                type: 'link'
            }
        ]
    },
    {
        title: 'Profilo utente',
        menucontent: 'Voci del profilo utente',
        menu: [
            {
                id: 3,
                title: 'Preferenze',
                url: `${process.env.PUBLIC_URL}/user/preferenze`,
                icon: 'knowledgebase',
                type: 'link'
            }
        ]
    }
];*/


export const menuProfiloSuperAdmin: SidebarMenuType[] = [
    {
        title: 'AMBIENTI',
        menu: [
            {
                title: 'Pages',
                icon: "home",
                type: "sub",
                menu: [
                    {
                        title: 'Dashboard',
                        url: `${process.env.PUBLIC_URL}/dashboard/default`,
                        type: "link",
                    },
                    {
                        title: 'Sample Page',
                        url: `${process.env.PUBLIC_URL}/pages/sample_page`,
                        type: "link",
                    },
                ],
            },
            {
                title: "Support Ticket",
                icon: "support-tickets",
                type: "sub",
                menu: [
                    {
                        title: "Raised Ticket",
                        target: '_blank',
                        url: `https://support.pixelstrap.com/`,
                        type: "link"
                    }
                ]
            },
            {
                title: "Amministrazione",
                icon: "support-tickets",
                type: "sub",
                menu: [
                    {
                        title: "Lista Utenti",
                        url: `${process.env.PUBLIC_URL}/pages/utenti`,
                        type: "link",
                    },
                    {
                        title: "Risorse",
                        url: `${process.env.PUBLIC_URL}/pages/risorse`,
                        type: "link",
                    },
                ]
            }
        ]
    }
];
export const menuProfiloCliente: SidebarMenuType[] = [
    {
        title: 'Admin',
        menu: [
            {
                title: 'Pages',
                icon: "home",
                type: "sub",
                menu: [
                    {
                        title: 'Dashboard',
                        url: `${process.env.PUBLIC_URL}/dashboard/default`,
                        type: "link",
                    },
                    {
                        title: 'Sample Page',
                        url: `${process.env.PUBLIC_URL}/pages/sample_page`,
                        type: "link",
                    },
                ],
            },
            {
                title: "Support Ticket",
                icon: "support-tickets",
                type: "sub",
                menu: [
                    {
                        title: "Raised Ticket",
                        target: '_blank',
                        url: `https://support.pixelstrap.com/`,
                        type: "link"
                    }
                ]
            },
            {
                title: "Amministrazione",
                icon: "support-tickets",
                type: "sub",
                menu: [
                    {
                        title: "Lista Utenti",
                        url: `${process.env.PUBLIC_URL}/pages/utenti`,
                        type: "link",
                    },
                    {
                        title: "Risorse",
                        url: `${process.env.PUBLIC_URL}/pages/risorse`,
                        type: "link",
                    },
                ]
            }
        ]
    }
]
export const menuProfiloCentro: SidebarMenuType[] = [
    {
        title: 'Admin',
        menu: [
            {
                title: 'Pages',
                icon: "home",
                type: "sub",
                menu: [
                    {
                        title: 'Dashboard',
                        url: `${process.env.PUBLIC_URL}/dashboard/default`,
                        type: "link",
                    },
                    {
                        title: 'Sample Page',
                        url: `${process.env.PUBLIC_URL}/pages/sample_page`,
                        type: "link",
                    },
                ],
            },
            {
                title: "Support Ticket",
                icon: "support-tickets",
                type: "sub",
                menu: [
                    {
                        title: "Raised Ticket",
                        target: '_blank',
                        url: `https://support.pixelstrap.com/`,
                        type: "link"
                    }
                ]
            },
            {
                title: "Amministrazione",
                icon: "support-tickets",
                type: "sub",
                menu: [
                    {
                        title: "Lista Utenti",
                        url: `${process.env.PUBLIC_URL}/pages/utenti`,
                        type: "link",
                    },
                    {
                        title: "Risorse",
                        url: `${process.env.PUBLIC_URL}/pages/risorse`,
                        type: "link",
                    },
                ]
            }
        ]
    }
];


export const menuList: SidebarMenuType[] = [
    {
        title: 'General',
        menu: [
            {
                title: 'Pages',
                icon: "home",
                type: "sub",
                menu: [
                    {
                        title: 'Dashboard',
                        url: `${process.env.PUBLIC_URL}/dashboard/default`,
                        type: "link",
                    },
                    {
                        title: 'Sample Page',
                        url: `${process.env.PUBLIC_URL}/pages/sample_page`,
                        type: "link",
                    },
                ],
            },
            {
                title: "Support Ticket",
                icon: "support-tickets",
                type: "sub",
                menu: [
                    {
                        title: "Raised Ticket",
                        target: '_blank',
                        url: `https://support.pixelstrap.com/`,
                        type: "link"
                    }
                ]
            },
            {
                title: "Amministrazione",
                icon: "support-tickets",
                type: "sub",
                menu: [
                    {
                        title: "Lista Utenti",
                        url: `${process.env.PUBLIC_URL}/pages/utenti`,
                        type: "link",
                    },
                    {
                        title: "Risorse",
                        url: `${process.env.PUBLIC_URL}/pages/risorse`,
                        type: "link",
                    },
                ]
            }
        ]
    }
];