import React from 'react'
import {Card, CardBody, Col} from 'reactstrap'
import {Link} from 'react-router-dom'
import DropdownWithHeader from '../../../../../Utils/CommonComponents/DashboardsCommon/DropdownWithHeader'
import {monthlyDropdownList, newsUpdateData} from '../../../../../Data/DashboardsData/DefaultData'
import {MinAgo, NewsUpdateTitle} from '../../../../../Utils/Constants'
import {Btn, H5, Image} from '../../../../../AbstractElements'
import {dynamicImage} from '../../../../../Utils'

export default function NewsUpdate() {
    return (
        <Col xl={12} md={6} className="box-col-6 col-xl-50">
            <Card className="news-update">
                <DropdownWithHeader headerClass='pb-0' heading={NewsUpdateTitle} dropDownList={monthlyDropdownList}
                                    dropDownClass='icon-dropdown' dropDownIcon={true}/>
                <CardBody>
                    {newsUpdateData.map((item) => (
                        <div className={`d-flex align-items-center ${item.id === 1 ? 'pt-0' : ''}`} key={item.id}>
                            <Image src={dynamicImage(`dashboard/${item.src}`)} alt=""/>
                            <div className="flex-grow-1 ms-3">
                                <Link to={`${process.env.PUBLIC_URL}/applications/social-app`}>
                                    <H5>{item.title}</H5>
                                    <span>{item.spanText}</span>
                                </Link>
                            </div>
                            <div className="flex-shrink-0">
                                <Btn color='transparent'>{item.time} {MinAgo} </Btn>
                            </div>
                        </div>
                    ))}
                </CardBody>
            </Card>
        </Col>
    )
}
