import React, {useState} from 'react'
import {Col, Form, FormGroup, Label, Input, FormFeedback, Row,} from "reactstrap";
import {useForm} from "react-hook-form";
import {
    ChooseFile,
    City,
    DescriptionLabel,
    FirstName,
    LastName,
    LooksGood,
    Email,
    Password,
    SubmitButton,
    UserName,
    Zip
} from '../../../Utils/Constants';
import DropItem from '../Common/DropItem';
import RadioPayMethod from '../Common/RadioPayMethod';
import {Btn} from '../../../AbstractElements';
import {FormData} from '../../../Types/FormType';
import ThemeSelect from './ThemeSelect';
import AuthService from "../../../Services/auth.service";
import UserService from "../../../Services/user.service";

export default function UtenteForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState<null | string>(null);
    const [validate, setValidate] = useState(false);
    const {register, handleSubmit, formState: {errors}} = useForm<FormData>();
    const onSubmit = async (data: any) => {
        try{
            setIsLoading(true);
            console.log(data);
            const response = await UserService.creaUtente(data.username,data.nome,data.cognome,data.email,data.password,data.password);
            // const response = await AuthService.register(data.username,data.nome,data.email,data.password,data.password);
            if (response != null) {
                if (response.created === false){
                    window.alert(response.message);
                }else{
                    setValidate(true);
                }
            }
        }catch(error){
            window.alert("ERRORE");
            setValidate(false);
        }finally {
            setIsLoading(false); // Disattiva la finestra di attesa, indipendentemente dall'esito della registrazione
        }
    };

    return (
        <Form className="needs-validation custom-input" onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-3">
                <Col xs={12} md={4}>
                    <FormGroup>
                        <Label>{UserName}</Label>
                        <input type="text"
                               className={`form-control ${errors.username ? 'is-invalid' : ''} ${validate && !errors.username ? 'is-valid' : ''}`}
                               placeholder="Mark" {...register("username", {required: "Please enter your valid name"})} />
                        {errors.username && (<FormFeedback>{errors.username.message}</FormFeedback>)}
                        {validate && !errors.username && <FormFeedback valid>{LooksGood}</FormFeedback>}
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <Row className="g-3">
                        <Col xs={6}>
                            <FormGroup>
                                <Label>{FirstName}</Label>
                                <input type="text"
                                       className={`form-control ${errors.nome ? 'is-invalid' : ''} ${validate && !errors.nome ? 'is-valid' : ''}`}
                                       placeholder="Mark" {...register("nome", {required: "Please enter your valid name"})} />
                                {errors.nome && (<FormFeedback>{errors.nome.message}</FormFeedback>)}
                                {validate && !errors.nome && <FormFeedback valid>{LooksGood}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>{LastName}</Label>
                                <input type="text"
                                       className={`form-control ${errors.cognome ? 'is-invalid' : ''} ${validate && !errors.cognome ? 'is-valid' : ''}`}
                                       placeholder="Mark" {...register("cognome", {required: "Please enter your valid name"})} />
                                {errors.cognome && (<FormFeedback>{errors.cognome.message}</FormFeedback>)}
                                {validate && !errors.cognome && <FormFeedback valid>{LooksGood}</FormFeedback>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col xl={6} md={3}>
                    <FormGroup>
                        <Label>{Email}</Label>
                        <input type="email"
                               className={`form-control ${errors.email ? 'is-invalid' : ''} ${validate && !errors.email ? 'is-valid' : ''}`}
                               placeholder="Mark" {...register("email", {required: "Please enter your valid name"})} />
                        {errors.email && (<FormFeedback>{errors.email.message}</FormFeedback>)}
                        {validate && !errors.email && <FormFeedback valid>{LooksGood}</FormFeedback>}
                    </FormGroup>
                </Col>
                <Col xl={5} md={3}>
                    <FormGroup>
                        <Label>{Password}</Label>
                        <input type="password"
                               className={`form-control ${errors.password ? 'is-invalid' : ''} ${validate && !errors.password ? 'is-valid' : ''}`} {...register("password", {required: "Please enter your valid password",})} />
                        {errors.password && (<FormFeedback>{errors.password.message}</FormFeedback>)}
                    </FormGroup>
                </Col>
                {/*<DropItem validate={true} colClass='col-12'*/}
                {/*          className={`${validate && !selectedOption ? "is-invalid" : ""} ${validate && selectedOption ? 'is-valid' : ''}`}*/}
                {/*          selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>*/}
                {/*<Col md={6}>*/}
                {/*    <FormGroup>*/}
                {/*        <Label>{City}</Label>*/}
                {/*        <input type="text"*/}
                {/*               className={`form-control ${errors.city ? 'is-invalid' : ''} ${validate && !errors.city ? 'is-valid' : ''}`} {...register("city", {required: "Please provide a valid city"})} />*/}
                {/*        {errors.city && (<FormFeedback>{errors.city.message}</FormFeedback>)}*/}
                {/*        {validate && !errors.city && <FormFeedback valid>{LooksGood}</FormFeedback>}*/}
                {/*    </FormGroup>*/}
                {/*</Col>*/}
                {/*<Col md={6}>*/}
                {/*    <FormGroup>*/}
                {/*        <Label>{Zip}</Label>*/}
                {/*        <input type="text"*/}
                {/*               className={`form-control ${errors.zip ? 'is-invalid' : ''} ${validate && !errors.zip ? 'is-valid' : ''}`} {...register('zip', {required: 'Please provide a valid zip'})} />*/}
                {/*        {errors.zip && <FormFeedback>{errors.zip.message}</FormFeedback>}*/}
                {/*        {validate && !errors.zip && <FormFeedback valid>{LooksGood}</FormFeedback>}*/}
                {/*    </FormGroup>*/}
                {/*</Col>*/}
                {/*<RadioPayMethod colClass='col-12'/>*/}
                {/*//TODO forse interessante per personalizzare le select*/}
                {/*<ThemeSelect/>*/}
                {/*<Col xs={12}>*/}
                {/*    <FormGroup>*/}
                {/*        <Label>{ChooseFile}</Label>*/}
                {/*        <input type="file"*/}
                {/*               className={`form-control ${errors.file ? 'is-invalid' : ''} ${validate && !errors.file ? 'is-valid' : ''}`} {...register('file', {required: 'Please select a file'})} />*/}
                {/*        {errors.file && <FormFeedback>{errors.file.message}</FormFeedback>}*/}
                {/*        {validate && !errors.file && <FormFeedback valid>{LooksGood}</FormFeedback>}*/}
                {/*    </FormGroup>*/}
                {/*</Col>*/}
                {/*<Col xs={12}>*/}
                {/*    <FormGroup>*/}
                {/*        <Label>{DescriptionLabel}</Label>*/}
                {/*        <textarea*/}
                {/*            className={`form-control ${errors.description ? 'is-invalid' : ''} ${validate && !errors.description ? 'is-valid' : ''}`} {...register('description', {required: 'Please enter a message'})} />*/}
                {/*        {errors.description && <FormFeedback>{errors.description.message}</FormFeedback>}*/}
                {/*        {validate && !errors.description && <FormFeedback valid>{LooksGood}</FormFeedback>}*/}
                {/*    </FormGroup>*/}
                {/*</Col>*/}
                <Col xs={12}>
                    <FormGroup check>
                        <Input type="checkbox" id="invalidCheck" required/>
                        <Label htmlFor="invalidCheck" check>{'Accetto i termini e condizioni'}</Label>
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <Btn color="primary">{SubmitButton}</Btn>
                </Col>
            </Row>
            {isLoading && <div className="loading-overlay">Attendere...</div>} {/* Finestra di attesa condizionale */}
        </Form>
    )
}
