import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL === undefined ? 'https://sport.artliver.it/' : process.env.REACT_APP_API_URL;

const register = (username, nome, email, password, password_confirm) => {
    return axios.post(API_URL + "user/registra", {
        username: username,
        nome: nome,
        email: email,
        password: password,
        password_confirm: password_confirm,
    })
        .then((response) => {
            console.log("############################# SONO QUIIIIIII");
            console.log(response.data)
            return response.data;
        });
};

const login = (ambiente, username, password) => {
    return axios
        .post(API_URL + "user/login", {
          ambiente_id: ambiente,
            username: username,
            password
        })
        .then((response) => {
            if (response.data.loggedIn == true) {
                localStorage.setItem("token", response.data.token);
            }
            return response.data;
        });
};

const loginSocial = (tipo, parametri) => {
    return axios
        .post(API_URL + "user/login", {
            social: true,
            tipo: tipo,
            parametri: parametri
        })
        .then((response) => {
            if (response.data.loggedIn == true) {
                localStorage.setItem("token", response.data.token);
            }
            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
    return axios.post(API_URL + "signout").then((response) => {
        return response.data;
    });
};

const getCurrentUser = () => {
    return axios
        .get(API_URL + "user/info", {
            headers: {
                'Token': localStorage.getItem("token")
            }
        })
        .then((response) => {
            console.log("API RESPONSE: " + response.data.dati);
            localStorage.setItem("token_status", response.data.status);
            return response.data.dati;
        });
    // return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
    loginSocial,
}

export default AuthService;
