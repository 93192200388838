import React, {FormEvent, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Btn, H3, H6, Image, P} from '../AbstractElements';
import {dynamicImage} from '../Utils';
import {Col, Form, FormGroup, Input, Label} from 'reactstrap';
import {
    CreateAccount,
    DontHaveAccount,
    EmailAddress,
    FacebookLink,
    ForgotPassword,
    LinkedIn,
    Password,
    RememberPassword,
    SignIn,
    SignInAccount,
    SignInWith,
    TwitterLink
} from '../Utils/Constants';
import FeatherIconCom from '../Utils/CommonComponents/CommonIcons/FeatherIconCom';
import AuthService from "../Services/auth.service";
import axios from "axios";
import {useDispatch} from "react-redux";
import {setUserData} from "../redux-toolkit/reducers/UserDataReducer";
import GoogleButton from "../Components/Login/Google";
import FacebookButton from "../Components/Login/Facebook";
import TwitterButton from "../Components/Login/Twitter";


export default function SignInForm() {
    const navigate = useNavigate();
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false); // Stato per il controllo del caricamento

    const API_URL = process.env.REACT_APP_API_URL === undefined ? 'https://sport.artliver.it/' : process.env.REACT_APP_API_URL;

    const [logo, setLogo] = useState({}); //Stato per il recupero degli stili del dominio
    const [background, setBackground] = useState({}); //Stato per il recupero degli stili del dominio
    axios.post(API_URL + "stili", {})
        .then((response) => {
            setLogo(response.data.logo);
            setBackground(response.data.background);

            const elements: HTMLCollectionOf<Element> = document.getElementsByClassName('login-card');
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i] as HTMLElement;
                element.style.background = response.data.background;
            }
        });
    const dispatch = useDispatch();

    const toggle = () => setPasswordVisible(!isPasswordVisible);
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()

        AuthService.login(3, email, password).then(
            (response) => {
                if (response.loggedIn) {
                    AuthService.getCurrentUser().then((response) => {
                        dispatch(setUserData(response));
                        if (response.approvato === '1') {
                            toast.success("Login Success...!");
                            localStorage.setItem("token_status", JSON.stringify(true));
                            navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
                        } else {
                            toast.warning("Utente in fase di approvazione")
                        }
                    });
                    // localStorage.setItem("login", JSON.stringify(true));
                } else {
                    // console.log(response)
                    window.alert(response.message);
                }
            },
            (error) => {
                window.alert("Errore in login");
            }
        );
    };
    return (
        <div>
            <div>
                <Link className='logo' to={`${process.env.PUBLIC_URL}/dashboard/default`}>
                    <Image className="img-fluid for-light" src={logo} alt="Logo"
                           body={true}/>
                </Link>
            </div>
            <div className="login-main">
                <Form className="theme-form" onSubmit={handleSubmit}>
                    <H3>{SignInAccount}</H3>
                    <P>{"Inserisci email e password per accedere"}</P>
                    <FormGroup>
                        <Col><Label>{EmailAddress}</Label></Col>
                        <Input defaultValue={email} onChange={(event) => setEmail(event.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Col><Label>{Password}</Label></Col>
                        <div className="form-input position-relative">
                            <Input type={isPasswordVisible ? 'text' : 'password'} defaultValue={password}
                                   onChange={(event) => setPassword(event.target.value)} name="login[password]"/>
                            <div className='show-hide' onClick={toggle}>
                                <span className={!isPasswordVisible ? 'show' : ''}></span>
                            </div>
                        </div>
                    </FormGroup>
                    <div className="mb-0 form-group">
                        <div className="checkbox p-0">
                            <Input id="checkbox1" type="checkbox"/>
                            <Label className="text-muted" htmlFor="checkbox1">{RememberPassword}</Label>
                        </div>
                        <Link className="link"
                              to={`${process.env.PUBLIC_URL}/auth/forget-password`}>{ForgotPassword}</Link>
                        <div className="text-end mt-3">
                            <Btn color='primary' className="btn-block w-100">{SignIn}</Btn>
                        </div>
                    </div>
                </Form>
                <H6 className="text-muted mt-4 or">{SignInWith}</H6>
                <div className="social mt-4">
                    <div className="btn-showcase">
                        <GoogleButton/>
                        <FacebookButton/>
                        <TwitterButton/>
                    </div>
                </div>
                <P className="mt-4 mb-0 text-center">{DontHaveAccount}
                    <Link className="ms-2" to={`${process.env.PUBLIC_URL}/auth/sign-up`}>{CreateAccount}</Link>
                </P>

            </div>
        </div>
    )
}
