import React from 'react'
import {Card, CardBody, Col} from 'reactstrap'
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader'
// import {ValidationFormTitle} from '../../../../../Utils/Constants'
// import {validationFormSubTitle} from '../../../../../Data/Forms/FormControlsData'
import UtenteForm from './Utente'
import CardHeaderSpan from "../../../Utils/CommonComponents/CardHeaderSpan";
import {DescrizioneElencoAmbienti, DescrizioneExtraElencoAmbienti} from "../../../Utils/Constants";

export default function CardUtenteForm() {

    const titoloForm = "Nuova utente";

    return (
        <Col xl={12}>
            <Card className="height-equal">
                <CardHeaderSpan headingClassName="pb-0 card-no-border" heading={titoloForm}
                                span={titoloForm}
                                bigHeadingClassName="mb-3"/>
                <CardBody>
                    <UtenteForm/>
                </CardBody>
            </Card>
        </Col>
    )
}
