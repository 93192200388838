import React, {ChangeEvent, Fragment, useEffect, useState} from 'react'
import {Col, FormGroup, Input, Label, Row} from 'reactstrap'
import {LI, UL} from '../../../AbstractElements'
import {TimeSlotType} from "../../../Types/TimeSlotType";
import {UseFormRegister} from "react-hook-form";
import {FormRisorsa} from "../../../Types/FormType";

interface Props {
    timeslots: TimeSlotType[]; // Definizione della prop timeslots
    orepiene: boolean; // Definizione della prop orepiene
    idprefix: string;
    // onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    register: UseFormRegister<FormRisorsa>
    formRisorsa: FormRisorsa;
    setFormRisorsa: React.Dispatch<React.SetStateAction<FormRisorsa>>;
}

export const SelettoreSlotOrari: React.FC<Props> = ({
                                                        timeslots,
                                                        orepiene,
                                                        idprefix,
                                                        register,
                                                        formRisorsa,
                                                        setFormRisorsa
                                                    }) => {
    // Stato per tenere traccia dello stato di ciascun checkbox
    const [checkboxesState, setCheckboxesState] = useState<{ [key: string]: boolean }>({});

    // Funzione per gestire il cambio di stato del checkbox principale
    const handleSelezionaTutto = (idprefix: string) => (event: ChangeEvent<HTMLInputElement>) => {
        const newState: { [key: string]: boolean } = {};
        // Imposta lo stato di tutti i checkbox in base allo stato del checkbox principale
        timeslots.forEach(data => {
            newState[`${idprefix}_${data.id}`] = event.target.checked;
        });
        setCheckboxesState(prevState => ({
            ...prevState,
            ...newState
        }));
    };

    const handleSingleCheckboxChange = (idprefix: string, checkboxId: number) => (e: ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        const newValue = idprefix + "_" + checkboxId;
        const initialArray = formRisorsa.slot_orario_composito || [];

        // Copia l'array esistente e aggiungi o rimuovi il valore in base allo stato del checkbox
        const updatedArray = isChecked
            ? [...initialArray, newValue]
            : initialArray.filter(value => !value.startsWith(idprefix));

        // Aggiorna lo stato formRisorsa con il nuovo array
        setFormRisorsa({...formRisorsa, slot_orario_composito: updatedArray});
    };

    useEffect(() => {
        const updatedSlotOrarioComposito = timeslots
            .filter(data => checkboxesState[`${idprefix}_${data.id}`])
            .map(data => idprefix + "_" + data.id);

        setFormRisorsa(prevState => ({
            ...prevState,
            slot_orario_composito: updatedSlotOrarioComposito
        }));
    }, [checkboxesState]);

    const isAllCheckboxSelected = timeslots.every(data => checkboxesState[`${idprefix}_${data.id}`]);

    return (
        <Row>
            <Col xl={12}>
                <FormGroup className="checkbox checkbox-solid-warning" check>
                    <Input id={"selezionatutto_" + idprefix} name={"selezionatutto_" + idprefix} type="checkbox"
                           value="1" onChange={handleSelezionaTutto(idprefix)}
                           checked={isAllCheckboxSelected}
                    />
                    <Label htmlFor={"selezionatutto_" + idprefix} className={"selezionatutto_label_" + idprefix}
                           check> Seleziona
                        tutti gli orari</Label>
                </FormGroup>
            </Col>
            <Col xl={12}>
                <div className="card-wrapper border rounded-3 checkbox-checked mb-2">
                    <FormGroup className="checkbox checkbox-primary ps-0 main-icon-checkbox"
                               check>
                        <UL className='simple-list flex-row checkbox-wrapper '>
                            {timeslots.map((data) => (
                                (orepiene && data.ora_minuti_inizio.endsWith("00")) || (!orepiene && data.ora_minuti_inizio.endsWith("30")) ? (
                                    <LI key={idprefix + "_" + data.id}>
                                        <input
                                            className={"checkbox-shadow form-control form-check-input selettore_orario_" + idprefix}
                                            id={idprefix + "_" + data.id} {...register("slot_orario_composito")}
                                            type="checkbox" value={idprefix + "_" + data.id}
                                            checked={(formRisorsa.slot_orario_composito || []).includes(idprefix + "_" + data.id)}
                                            onChange={handleSingleCheckboxChange(idprefix, data.id)}/>
                                        <Label htmlFor={idprefix + "_" + data.id}
                                               check><span>{data.ora_minuti_inizio}</span></Label>
                                    </LI>
                                ) : null
                            ))}
                        </UL>
                    </FormGroup>
                </div>
            </Col>
        </Row>
    )
}
export default SelettoreSlotOrari;