import React from 'react'
import {Card, CardBody, Col} from 'reactstrap'
import DropdownWithHeader from '../../../../../Utils/CommonComponents/DashboardsCommon/DropdownWithHeader'
import {ProductStockTitle} from '../../../../../Utils/Constants'
import {monthlyDropdownList, productOptions} from '../../../../../Data/DashboardsData/DefaultData'
import {H2} from '../../../../../AbstractElements'
import ReactApexChart from 'react-apexcharts'

export default function ProductStock() {
    return (
        <Col xl={12} md={6} className="col-xl-50 box-col-6">
            <Card className="product-chart mb-0">
                <DropdownWithHeader headerClass='pb-0' heading={ProductStockTitle} dropDownList={monthlyDropdownList}
                                    dropDownClass='icon-dropdown' dropDownIcon={true}/>
                <CardBody className="pb-0">
                    <div className="d-flex">
                        <H2 className="me-2">{'($1,23,456)'}</H2><span>{'Total Product Stock'}</span>
                    </div>
                    <div className="product-chart">
                        <div className="shap-block">
                            <div className="rounded-shap animate-bg-secondary"><i></i><i></i></div>
                        </div>
                        <div id="product-chart">
                            <ReactApexChart type='area' options={productOptions} series={productOptions.series}
                                            height={350}/>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}
