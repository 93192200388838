import React from 'react'
import {Card, CardBody, Col} from 'reactstrap'
import ReactApexChart from 'react-apexcharts'
import DropdownWithHeader from '../../../../../Utils/CommonComponents/DashboardsCommon/DropdownWithHeader'
import {LeadsStatusTitle, TotalInvestmentTitle} from '../../../../../Utils/Constants'
import {
    investmentOptions,
    leadsStatusData,
    monthlyDropdownList,
    totalInvestmentData
} from '../../../../../Data/DashboardsData/DefaultData'
import {H5, Image, LI, UL} from '../../../../../AbstractElements'
import {dynamicImage} from '../../../../../Utils'

export default function TotalInvestmentAndLeadsStatus() {
    return (
        <>
            <Col xxl={5} xl={50} md={6}>
                <Card>
                    <DropdownWithHeader headerClass='card-no-border pb-0' heading={TotalInvestmentTitle}
                                        dropDownList={monthlyDropdownList} dropDownClass='icon-dropdown'
                                        dropDownIcon={true}/>
                    <CardBody className="p-0 pb-3 pt-3">
                        <div className="investment-card">
                            <div id="Investment-chart">
                                <ReactApexChart type='donut' options={investmentOptions}
                                                series={investmentOptions.series} height={200}/>
                            </div>
                            <UL className='simple-list flex-row'>
                                {totalInvestmentData.map((item, index) => (
                                    <LI key={index}><H5>{item.text}</H5><span>{'$'}{item.rate}</span></LI>
                                ))}
                            </UL>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col xxl={12}>
                <Card className="leads">
                    <DropdownWithHeader headerClass='card-no-border pb-0' heading={LeadsStatusTitle}
                                        dropDownList={monthlyDropdownList} dropDownClass='icon-dropdown'
                                        dropDownIcon={true}/>
                    <CardBody>
                        <div className="lead-status">
                            {leadsStatusData.map((item) => (
                                <UL className='simple-list' key={item.id}>
                                    {item.child.map((list) => (
                                        <LI className={list.class} key={list.id}>
                                            {list.image &&
                                                <Image src={dynamicImage(`dashboard/user/${list.image}`)} alt=""/>}
                                            {list.text}
                                        </LI>
                                    ))}
                                </UL>
                            ))}
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}
