import React, {useEffect, useState} from 'react';
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Col,
    Row,
    CardBody,
    CardFooter,
    Modal,
    ModalHeader,
    ModalBody,
    Card
} from 'reactstrap';
import {UserDataInterface} from '../../../../Types/UserDataType';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../redux-toolkit/store';
import {useForm} from "react-hook-form";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import {nazioniList} from "../../../../Data/CommonData/NazioniList";
import {UpdateProfile, Save} from "../../../../Utils/Constants";
import {Btn, H3, H6, P, Image} from "../../../../AbstractElements";
import Cleave from "cleave.js/react";
import UserService from "../../../../Services/user.service";

const UserDataForm = () => {
    const userData = useSelector((state: RootState) => state.userData);
    const [formValues, setFormValues] = useState<{
        nome: string,
        cognome: string,
        email: string,
        nascita_data: string,
        nascita_luogo: string,
        codice_fiscale: string,
        residenza_indirizzo: string,
        residenza_civico: string,
        residenza_luogo: string,
        residenza_cap: number,
        data_registrazione: string,
        api_key: string,
        api_secret: string,
        approvato: string,
    }>({
        nome: userData.nome_utente,
        cognome: userData.cognome_utente,
        email: userData.email,
        nascita_data: userData.nascita_data,
        nascita_luogo: userData.nascita_luogo,
        codice_fiscale: userData.codice_fiscale,
        residenza_indirizzo: userData.visibile_indirizzo,
        residenza_civico: userData.visibile_civico,
        residenza_luogo: userData.visibile_citta,
        residenza_cap: userData.visibile_cap,
        data_registrazione: userData.data_registrazione,
        api_key: userData.api_key,
        api_secret: userData.api_secret,
        approvato: userData.approvato,
    });
    const [editing, setEditing] = useState(false); // Variabile di stato per la modalità di modifica

    useEffect(() => {
        // Aggiorna formValues ogni volta che userData cambia
        setFormValues({
            nome: userData.nome_utente,
            cognome: userData.cognome_utente,
            email: userData.email,
            nascita_data: userData.nascita_data,
            nascita_luogo: userData.nascita_luogo,
            codice_fiscale: userData.codice_fiscale,
            residenza_indirizzo: userData.visibile_indirizzo,
            residenza_civico: userData.visibile_civico,
            residenza_luogo: userData.visibile_citta,
            residenza_cap: userData.visibile_cap,
            data_registrazione: userData.data_registrazione,
            api_key: userData.api_key,
            api_secret: userData.api_secret,
            approvato: userData.approvato,
        });
    }, [userData]); // Aggiungi userData come dipendenza

    const {register, handleSubmit, formState: {errors}} = useForm();

    const toggleEditing = () => {
        setEditing(!editing); // Cambia la modalità di modifica
    };

    const onEditSubmit = () => {
        alert('Funzionalità in sviluppo');
        UserService.salvaDatiUtente(userData.utente_id,formValues).then((response) => {

        });
    };

    const [base64Avatar, setBase64Avatar] = useState<string>();
    useEffect(() => {
        setBase64Avatar("data:image/jpeg;base64," + userData.avatar);
    }, [userData.avatar]);

    return (
        <Card>
            <Form onSubmit={handleSubmit(onEditSubmit)}>
                <CommonCardHeader headClass='pb-2 pt-2' titleClass='card-title'
                                  title={formValues.cognome + " " + formValues.nome}/>
                <CardBody>
                    <Row>
                        <Col xl={3}>
                            <Row className="mb-2 mt-3">
                                <div className="profile-title">
                                    <div className="d-flex">
                                        <Image className="img-70 rounded-circle" alt="" src={base64Avatar} body={true}/>
                                    </div>
                                </div>
                            </Row>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input type="email" placeholder="your-email@domain.com"
                                       defaultValue={formValues.email} disabled={true}/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Password</Label>
                                <Input type="password" disabled={true}/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Conferma Password</Label>
                                <Input placeholder="Conferma Password" type="password" disabled={true}/>
                            </FormGroup>
                            <div className="form-footer">
                                {/*<Btn color='primary' className="btn-block">{Save}</Btn>*/}
                            </div>
                        </Col>
                        <Col xl={8}>
                            <Row>
                                <Col sm={6} md={6}>
                                    <FormGroup>
                                        <Label>Nome</Label>
                                        <input className="form-control" type="text"
                                               placeholder="Nome" {...register('nome', {required: true})}
                                               defaultValue={formValues.nome} disabled={!editing}/><span
                                        style={{color: 'red'}}>{errors.nome_utente && 'Nome è obbligatorio'} </span>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} md={6}>
                                    <FormGroup>
                                        <Label>Cognome</Label>
                                        <input className="form-control" type="text"
                                               placeholder="Cognome" {...register('cognome', {required: true})}
                                               defaultValue={formValues.cognome} disabled={!editing}/><span
                                        style={{color: 'red'}}>{errors.cognome_utente && 'Cognome è obbligatorio'} </span>
                                    </FormGroup>
                                </Col>
                                <Col sm={3} md={3}>
                                    <FormGroup>
                                        <Label>Data nascita</Label>
                                        <Input className="digits" {...register('nascita_data', {required: false})}
                                               type="date" defaultValue={formValues.nascita_data} disabled={!editing}/>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} md={4}>
                                    <FormGroup>
                                        <Label>Luogo nascita</Label>
                                        <input className="form-control" type="text"
                                               placeholder="Comune nascita" {...register('nascita_luogo', {required: false})}
                                               defaultValue={formValues.nascita_luogo} disabled={!editing}/><span
                                        style={{color: 'red'}}>{errors.nascita_luogo && ''} </span>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} md={4}>
                                    <FormGroup>
                                        <Label>Codice Fiscale</Label>
                                        <input className="form-control" type="text"
                                               placeholder="Codice Fiscale" {...register('codice_fiscale', {required: false})}
                                               defaultValue={formValues.codice_fiscale} disabled={!editing}/><span
                                        style={{color: 'red'}}>{errors.codice_fiscale && ''} </span>
                                    </FormGroup>
                                </Col>
                                <Col md={11}>
                                    <FormGroup>
                                        <Label>Indirizzo</Label>
                                        <input className="form-control" type="text"
                                               placeholder="Indirizzo" {...register('residenza_indirizzo', {required: true})}
                                               defaultValue={formValues.residenza_indirizzo} disabled={!editing}/><span
                                        style={{color: 'red'}}>{errors.visibile_indirizzo && 'Indirizzo è obbligatorio'} </span>
                                    </FormGroup>
                                </Col>
                                <Col sm={1} md={1}>
                                    <FormGroup>
                                        <Label>Civico</Label>
                                        <input className="form-control" type="text"
                                               placeholder="Civico" {...register('residenza_civico', {required: false})}
                                               defaultValue={formValues.residenza_civico} disabled={!editing}/><span
                                        style={{color: 'red'}}>{errors.visibile_civico && ''} </span>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} md={4}>
                                    <FormGroup>
                                        <Label>Comune</Label>
                                        <input className="form-control" type="text"
                                               placeholder="Comune" {...register('residenza_luogo', {required: true})}
                                               defaultValue={formValues.residenza_luogo} disabled={!editing}/><span
                                        style={{color: 'red'}}>{errors.visibile_citta && 'Comune è obbligatorio'} </span>
                                    </FormGroup>
                                </Col>
                                <Col sm={2} md={2}>
                                    <FormGroup>
                                        <Label>CAP</Label>
                                        <input className="form-control" type="number"
                                               placeholder="CAP" {...register('residenza_cap', {required: true})}
                                               defaultValue={formValues.residenza_cap} disabled={!editing}/><span
                                        style={{color: 'red'}}>{errors.visibile_cap && 'CAP è obbligatorio'} </span>
                                    </FormGroup>
                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <Label>Nazione</Label>
                                        <Input type="select" className=" btn-square" disabled={!editing}>
                                            {nazioniList.map((items, i) => <option key={i}>{items}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={4} md={4}>
                                    <FormGroup>
                                        <Label>Telefono</Label>
                                        <Input type="tel" defaultValue="XXX-XXXXXXX" className="m-input digits"/>
                                        {/*<Cleave className="form-control" options={{phone: true}}*/}
                                        {/*        placeholder="XXX-XXXXXXX"/>*/}
                                    </FormGroup>
                                </Col>
                                <Col sm={4} md={4}>
                                    <FormGroup>
                                        <Label>Data Registrazione</Label>
                                        <Input className="digits" id="example-datetime-local-input" type="datetime-local"
                                               defaultValue={formValues.data_registrazione}/>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} md={6}>
                                    <FormGroup>
                                        <Label>ApiKey</Label>
                                        <input className="form-control" type="text"
                                               placeholder=""
                                               defaultValue={formValues.api_key} disabled={true}/>
                                        <span style={{color: 'red'}}>{errors.api_key && ''} </span>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} md={6}>
                                    <FormGroup>
                                        <Label>Api secret</Label>
                                        <input className="form-control" type="text"
                                               placeholder=""
                                               defaultValue={formValues.api_secret} disabled={true}/>
                                        <span style={{color: 'red'}}>{errors.api_secret && ''} </span>
                                    </FormGroup>
                                </Col>
                                {/*<Col sm={6} md={6}>*/}
                                {/*    <FormGroup>*/}
                                {/*        <Label>IV</Label>*/}
                                {/*        <input className="form-control" type="text"*/}
                                {/*               placeholder="" {...register('IV', {required: false})}*/}
                                {/*               defaultValue={} disabled={true}/>*/}
                                {/*        <span style={{color: 'red'}}>{errors.api_secret && ''} </span>*/}
                                {/*    </FormGroup>*/}
                                {/*</Col>*/}

                                <Col md={5}>
                                        <FormGroup className={`checkbox ${formValues.approvato === '1' ? 'checkbox-solid-success' : 'checkbox-solid-warning'}`} check>
                                            <Input id="solid4" type="checkbox"  defaultChecked={formValues.approvato === '1'}/>
                                            <Label htmlFor="solid4" check>Approvato</Label>
                                        </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <div>
                                        <Label>Descrizione</Label>
                                        <Input type="textarea" rows="4" placeholder="Scrivi una tua descrizione"
                                               disabled={!editing}/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter className="text-center mt-3">
                    <Btn color='primary'>{Save}</Btn>
                    <Button className="m-l-5" color="secondary"
                            onClick={toggleEditing}>{editing ? 'Annulla' : 'Modifica'}</Button>
                </CardFooter>
            </Form>
        </Card>
    );
};

export default UserDataForm;
