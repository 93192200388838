import React, {useEffect, useState} from 'react'
import DataTable from 'react-data-table-component'
import {Card, CardBody, Col} from 'reactstrap'
import CardHeaderSpan from '../../../../../../Utils/CommonComponents/CardHeaderSpan'
import {
    colonneElenco,
    ColonneHtmlElenco
} from '../../../../../../Data/Tables/Colonne/Superadmin/ElencoDiscipline'
import {colonneElencoData} from '../../../../../../Data/Tables/Dati/Superadmin/ElencoDisciplineData'
import {
    CreaNuova, CreaNuovo,
    DescrizioneElencoDiscipline,
    DescrizioneExtraElencoDiscipline,
    HtmlTableTitle
} from '../../../../../../Utils/Constants'
import {HtmlColumnsInterface} from '../../../../../../Types/TableType'
import FilterComponent from '../../../Common/FilterComponent'
import {Btn} from "../../../../../../AbstractElements";
import {Link} from "react-router-dom";

export default function ElencoData() {
    const [filterText, setFilterText] = useState('');
    /*  const filteredItems: ColonneHtmlElencoPrenotazioni[] = colonneElencoPrenotazioniData.filter(
          (item: ColonneHtmlElencoPrenotazioni) => {
              return Object.values(item).some((value) =>
                  value && value.toString().toLowerCase().includes(filterText.toLowerCase())
              );
          }
      );
  */
    const [filteredItems, setFilteredItems] = useState<ColonneHtmlElenco[]>([]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem("token");
                const currentItems: ColonneHtmlElenco[] = await colonneElencoData();

                setFilteredItems(
                    currentItems
                );
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchUserData();
    }, []);

    return (
        <Col sm={12}>
            <Card>
                <CardHeaderSpan headingClassName="pb-0 card-no-border" heading={DescrizioneElencoDiscipline}
                                span={DescrizioneExtraElencoDiscipline}
                                bigHeadingClassName="mb-3"/>
                <CardBody>
                    <Link to="nuova">
                        <Btn color='secondary' className="mb-3">{CreaNuova}</Btn>
                    </Link>
                    <FilterComponent
                        onFilter={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                        onClear={() => setFilterText('')}
                        filterText={filterText}
                    />
                    <div className="table-responsive custom-scrollbar">
                        <DataTable data={filteredItems} columns={colonneElenco} striped={true} pagination/>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}
