import {TableColumn} from "react-data-table-component";
import {CustomCellInterFace} from "../../../../Types/TableType";
import {Badges, LI, UL} from "../../../../AbstractElements";
import {Href} from "../../../../Utils/Constants";


const CustomBadge = ({position, color}: CustomCellInterFace) => {
    return (
        <Badges className="rounded-pill" color={color}>{position}</Badges>
    );
};

export interface ColonneHtmlElenco {
    id: number;
    nome_visibile: string;
    codice_fiscale: string;
    partita_iva: string;
    visibile_indirizzo: string;
    visibile_civico: string;
    visibile_citta: string;
    visibile_telefono: string;
    visibile_riferimento: string;
    logo: string;
}

export const colonneElenco: TableColumn<ColonneHtmlElenco>[] = [
    {
        name: "Id",
        selector: (row) => row.id,
        sortable: true,
    },
    {
        name: "Nome",
        selector: (row) => row.nome_visibile,
        sortable: true,
    },
    {
        name: "Partita IVA / Codice fiscale",
        selector: (row) => (row.partita_iva != undefined && row.partita_iva.length > 2) ? row.partita_iva : row.codice_fiscale,
        sortable: true,
    },
    {
        name: "Indirizzo",
        selector: (row) => row.visibile_indirizzo + " " + row.visibile_civico,
        sortable: true,
    },
    {
        name: "Località",
        selector: (row) => row.visibile_citta,
        sortable: true,
    },
    {
        name: "Telefono",
        selector: (row) => row.visibile_telefono,
        sortable: true,
    },
    {
        name: "Riferimento",
        selector: (row) => row.visibile_riferimento,
        sortable: true,
    },
    {
        name: "Logo",
        cell: (row) => (
            <img src={"data:image/png;base64," + row.logo} alt={"Logo"} style={{maxHeight: 20}}/>
        ),
        sortable: true,
    },
    {
        name: "Azioni",
        sortable: true,
        cell: () => (
            <UL className="action simple-list flex-row">
                <LI className="modifica_ambiente">
                    <a href={Href}>
                        <i className="icon-text"/>
                    </a>
                </LI>
                <LI className="elimina_ambiente">
                    <a href={Href}>
                        <i className="icon-trash"/>
                    </a>
                </LI>
            </UL>
        ),
    },
];
