import React from 'react'
import {Card, CardBody, Col} from 'reactstrap'
import Slider from 'react-slick';
import DropdownWithHeader from '../../../../../Utils/CommonComponents/DashboardsCommon/DropdownWithHeader'
import {ReviewTitle} from '../../../../../Utils/Constants'
import {
    monthlyDropdownList,
    reviewSliderData,
    reviewSliderOptions
} from '../../../../../Data/DashboardsData/DefaultData'
import {H2, H5, Image, P} from '../../../../../AbstractElements';
import {dynamicImage} from '../../../../../Utils';

export default function ReviewSlider() {
    return (
        <Col xxl={3} md={4} className="box-col-6 col-dash-40 proorder-xl-7">
            <Card className="review-slider">
                <DropdownWithHeader headerClass='card-no-border pb-0' heading={ReviewTitle}
                                    dropDownList={monthlyDropdownList} dropDownClass='icon-dropdown'
                                    dropDownIcon={true}/>
                <CardBody>
                    <div className="owl-carousel owl-theme mb-2" id="owl-carousel-dashboard">
                        <Slider {...reviewSliderOptions}>
                            {reviewSliderData.map((item) => (
                                <div className="review" key={item.id}>
                                    <div><Image className="img-fluid" src={dynamicImage(`dashboard/user/${item.src}`)}
                                                alt=""/>
                                        <div className="review-content">
                                            <H2>{item.title}</H2>
                                        </div>
                                        <div className="d-flex">
                                            <H5>{item.rate}</H5>
                                            <P className="pull-right ms-2 me-2 mb-0 mt-0"><i
                                                className="fa fa-star font-secondary"></i></P>
                                            <div className="flex-grow-1">
                                                <H5>{item.rate2}</H5>
                                            </div>
                                        </div>
                                        <P>{'“Best  Product i’ve been seen on market, best price, best quality! Overall Amazing!!!”'}</P>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}
