import React, {useState} from 'react'
import {Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import {Link} from 'react-router-dom'
import DropdownWithHeader from '../../../../../Utils/CommonComponents/DashboardsCommon/DropdownWithHeader'
import {Notifications} from '../../../../../Utils/Constants'
import {monthlyDropdownList, notificationCardData} from '../../../../../Data/DashboardsData/DefaultData'
import {H5, Image, LI, P, UL} from '../../../../../AbstractElements'
import {dynamicImage} from '../../../../../Utils'

export default function NotificationCard() {
    const [openStates, setOpenStates] = useState(Array(notificationCardData.length).fill(false));
    const toggle = (index: number) => {
        const newOpenStates = [...openStates];
        newOpenStates[index] = !newOpenStates[index];
        setOpenStates(newOpenStates);
    };
    return (
        <Col xl={12} md={6} className="notification-card">
            <Card className="custom-scrollbar">
                <DropdownWithHeader headerClass='card-no-border pb-0' heading={Notifications}
                                    dropDownList={monthlyDropdownList} dropDownClass='icon-dropdown'
                                    dropDownIcon={true}/>
                <CardBody>
                    <UL className="notification-box simple-list">
                        {notificationCardData.map((item) => (
                            <LI className="d-flex" key={item.id}>
                                <div className="flex-shrink-0"><Image src={dynamicImage(`dashboard/user/${item.image}`)}
                                                                      alt=""/></div>
                                <div className="flex-grow-1">
                                    <Link to={`${process.env.PUBLIC_URL}/applications/chat/chat-private`}>
                                        <H5>{item.title}</H5>
                                    </Link>
                                    <P>{item.text}</P>
                                </div>
                                <Dropdown isOpen={openStates[item.id]} toggle={() => toggle(item.id)}
                                          className='icon-dropdown'>
                                    <DropdownToggle color='transparent'><i className='icon-more-alt'/></DropdownToggle>
                                    <DropdownMenu end={true}>
                                        {monthlyDropdownList.map((item, index) => (
                                            <DropdownItem key={index}>{item}</DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                            </LI>
                        ))}
                    </UL>
                </CardBody>
            </Card>
        </Col>
    )
}
