import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DateClickArg } from '@fullcalendar/interaction';
import ModaleMetronic from '../DataSource/Admin/Prenotazioni/ModalePrenotazioni';


  

export default function CalendarioBase() {
  const [modalOpen, setModalOpen] = useState(false);
  const [events, setEvents] = useState([{ title: 'Evento esistente', date: '2024-03-01' }]);
  const [view, setView] = useState("dayGridWeek"); // Imposta la vista iniziale come mese

  const handleDateClick = (arg: DateClickArg) => {
    setModalOpen(true); // Apri il modale quando un giorno è cliccato
  };

  const handleSave = (title: string, date: string, time: string) => {
    const newEvent = { title, date, start: `${date}T${time}` };
    setEvents([...events, newEvent]);
    setModalOpen(false); // Chiudi il modale dopo il salvataggio
  };

  const toggleView = () => {
    setView(view === "dayGridMonth" ? "dayGridWeek" : "dayGridMonth");
  };

  return (
    <div>
      <button className="btn btn-primary" onClick={toggleView}>Cambia Visualizzazione</button>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView={view} // Dinamicamente cambia la vista in base allo stato
        weekends={true}
        events={events}
        dateClick={handleDateClick}
        nowIndicator={true} // Mostra un indicatore per l'ora corrente
        fixedWeekCount={false} // Non usare un numero fisso di righe per il mese
      />
      <ModaleMetronic isOpen={modalOpen} onClose={() => setModalOpen(false)} onSave={handleSave} />
    </div>
  );
}
