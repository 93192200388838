import React from 'react'
import ElencoContainer from '../../../../Components/Tables/DataTables/DataSource/Superadmin/Centri'

export default function Centri() {
    return (
        <div className='page-body'>
            <ElencoContainer/>
        </div>
    )
}
