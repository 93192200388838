import React, {Fragment} from 'react'
import CardAmbienteForm from "../../../../Components/Forms/Ambiente/Nuovo";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import {AmbienteTitle, NuovoTitle} from "../../../../Utils/Constants";
import {Container, Row} from "reactstrap";


export default function NuovoAmbiente() {
    return (
        <div className='page-body'>
            <Fragment>
                <Breadcrumbs pageTitle={NuovoTitle + " " + AmbienteTitle} parent={AmbienteTitle}
                             title={NuovoTitle}/>
                <Container fluid>
                    <Row>
                        <CardAmbienteForm/>
                    </Row>
                </Container>
            </Fragment>
        </div>
    )
}