import React from 'react';
import {H5, H6, Image, LI, P, UL} from '../../../AbstractElements'
import {Checkall, Href, Message} from '../../../Utils/Constants'
import {X} from 'react-feather'
import {messageHeaderData} from '../../../Data/HeaderData/RightHeaderData'
import {Link} from 'react-router-dom'
import SvgIcon from '../../../Utils/CommonComponents/CommonIcons/CommonSvgIcons'
import {dynamicImage} from '../../../Utils';

export default function MessageHeader() {
    return (
        <LI className="onhover-dropdown">
            <div className="message">
                <SvgIcon iconId='fill-message'/>
                <span className="rounded-pill badge-secondary"></span>
            </div>
            <div className="onhover-show-div message-dropdown">
                <H6 className="f-18 mb-0 dropdown-title">{Message}</H6>
                <UL>
                    {messageHeaderData.map((data) => (
                        <LI key={data.id}>
                            <div className="d-flex align-items-start">
                                <div className="message-img bg-light-primary">
                                    <Image src={dynamicImage(data.src)} alt=""/></div>
                                <div className="flex-grow-1">
                                    <H5 className="mb-1">
                                        <Link to={Href}>{data.name}</Link>
                                    </H5>
                                    <P>{data.text}</P>
                                </div>
                                <div className="notification-right"><X/></div>
                            </div>
                        </LI>
                    ))}
                    <LI>
                        <Link className="f-w-700" to={Href}>{Checkall}</Link>
                    </LI>
                </UL>
            </div>
        </LI>
    )
}
