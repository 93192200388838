import React, {Fragment} from 'react'
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import {DisciplinaTitle, NuovoTitle} from "../../../../Utils/Constants";
import {Container, Row} from "reactstrap";
import CardDisciplinaForm from "../../../../Components/Forms/Disciplina/Nuova";


export default function NuovaDisciplina() {
    return (
        <div className='page-body'>
            <Fragment>
                <Breadcrumbs pageTitle={NuovoTitle + " " + DisciplinaTitle} parent={DisciplinaTitle}
                             title={NuovoTitle}/>
                <Container fluid>
                    <Row>
                        <CardDisciplinaForm/>
                    </Row>
                </Container>
            </Fragment>
        </div>
    )
}