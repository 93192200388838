import {TableColumn} from "react-data-table-component";
import {CustomCellInterFace} from "../../../../Types/TableType";
import {Badges, LI, UL} from "../../../../AbstractElements";
import {Href} from "../../../../Utils/Constants";


const handlePDFClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const pdfUrl = '/assets/pdf/sample.pdf';
    window.open(pdfUrl, '_blank');
};

const CustomBadge = ({position, color}: CustomCellInterFace) => {
    return (
        <Badges className="rounded-pill" color={color}>{position}</Badges>
    );
};

export interface ColonneHtmlElencoPrenotazioni {
    id: number;
    data_prenotazione: string;
    utente_prenotante: string;
    risorsa: string;
    importo_totale: string;
    importo_pagato: string;
    stato: string;
}

export const colonneElencoPrenotazioni: TableColumn<ColonneHtmlElencoPrenotazioni>[] = [
    {
        name: "Id",
        selector: (row) => row.id,
        sortable: true,
    },
    {
        name: "Data prenotazione",
        selector: (row) => row.data_prenotazione,
        sortable: true,
    },
    {
        name: "Utente prenotante",
        selector: (row) => row.utente_prenotante,
        sortable: true,
    },
    {
        name: "Risorsa",
        selector: (row) => row.risorsa,
        sortable: true,
    },
    {
        name: "Importo totale",
        selector: (row) => row.importo_totale,
        sortable: true,
    },
    {
        name: "Importo pagato",
        selector: (row) => row.importo_pagato,
        sortable: true,
    },
    {
        name: "Stato",
        selector: (row) => row.stato,
        cell: (row) => (
            <CustomBadge color={row.stato == 'attiva' ? 'blue' : 'red'} position={row.stato}/>
        ),
        sortable: true,
    },
    {
        name: "Azioni",
        sortable: true,
        cell: () => (
            <UL className="action simple-list flex-row">
                <LI className="modifica">
                    <a href={Href}>
                        <i className="icon-text"/>
                    </a>
                </LI>
                <LI className="elimina">
                    <a href={Href}>
                        <i className="icon-trash"/>
                    </a>
                </LI>
            </UL>
        ),
    },
];
