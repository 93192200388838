import React, {Fragment} from 'react'
import Breadcrumbs from '../../../../../../CommonElements/Breadcrumbs'
import {ElencoTitle, CentriTitle} from '../../../../../../Utils/Constants'
import {Container, Row} from 'reactstrap'
import ElencoData from './ElencoData'

export default function ElencoContainer() {
    return (
        <Fragment>
            <Breadcrumbs pageTitle={ElencoTitle + " " + CentriTitle} parent={CentriTitle}
                         title={ElencoTitle}/>
            <Container fluid>
                <Row>
                    <ElencoData/>
                </Row>
            </Container>
        </Fragment>
    )
}
