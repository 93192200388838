import React, {Fragment, useEffect, useState} from 'react';
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Col,
    Row,
    CardBody,
    CardFooter,
    Modal,
    ModalHeader,
    ModalBody,
    Card, Container, CardTitle, InputGroup, InputGroupText
} from 'reactstrap';
import {UserDataInterface} from '../../../../Types/UserDataType';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../redux-toolkit/store';
import {useForm} from "react-hook-form";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import {nazioniList} from "../../../../Data/CommonData/NazioniList";
import {UpdateProfile, Save, ElencoTitle, UtentiTitle, Title} from "../../../../Utils/Constants";
import {Btn, H3, H6, P, Image, LI, UL} from "../../../../AbstractElements";
import Cleave from "cleave.js/react";
import UserService from "../../../../Services/user.service";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import ElencoData from "../../../../Components/Tables/DataTables/DataSource/Admin/Metodipagamento/ElencoData";
import {ImpostazioniTitle} from "../../../../Utils/Constants";
import {iconCheckboxData} from "../../../../Data/Forms/FormControlsData";

const ImpostazioniForm = () => {
    const userData = useSelector((state: RootState) => state.userData);
    const [formValues, setFormValues] = useState<{
        nome: string,
        cognome: string,
        email: string,
        nascita_data: string,
        nascita_luogo: string,
        codice_fiscale: string,
        residenza_indirizzo: string,
        residenza_civico: string,
        residenza_luogo: string,
        residenza_cap: number,
        data_registrazione: string,
        api_key: string,
        api_secret: string,
        approvato: string,
    }>({
        nome: userData.nome_utente,
        cognome: userData.cognome_utente,
        email: userData.email,
        nascita_data: userData.nascita_data,
        nascita_luogo: userData.nascita_luogo,
        codice_fiscale: userData.codice_fiscale,
        residenza_indirizzo: userData.visibile_indirizzo,
        residenza_civico: userData.visibile_civico,
        residenza_luogo: userData.visibile_citta,
        residenza_cap: userData.visibile_cap,
        data_registrazione: userData.data_registrazione,
        api_key: userData.api_key,
        api_secret: userData.api_secret,
        approvato: userData.approvato,
    });
    const [editing, setEditing] = useState(false); // Variabile di stato per la modalità di modifica

    useEffect(() => {
        // Aggiorna formValues ogni volta che userData cambia
        setFormValues({
            nome: userData.nome_utente,
            cognome: userData.cognome_utente,
            email: userData.email,
            nascita_data: userData.nascita_data,
            nascita_luogo: userData.nascita_luogo,
            codice_fiscale: userData.codice_fiscale,
            residenza_indirizzo: userData.visibile_indirizzo,
            residenza_civico: userData.visibile_civico,
            residenza_luogo: userData.visibile_citta,
            residenza_cap: userData.visibile_cap,
            data_registrazione: userData.data_registrazione,
            api_key: userData.api_key,
            api_secret: userData.api_secret,
            approvato: userData.approvato,
        });
    }, [userData]); // Aggiungi userData come dipendenza

    const {register, handleSubmit, formState: {errors}} = useForm();

    const toggleEditing = () => {
        setEditing(!editing); // Cambia la modalità di modifica
    };

    const onEditSubmit = () => {
        alert('Funzionalità in sviluppo');
        UserService.salvaDatiUtente(userData.utente_id, formValues).then((response) => {

        });
    };

    const [base64Avatar, setBase64Avatar] = useState<string>();
    useEffect(() => {
        setBase64Avatar("data:image/jpeg;base64," + userData.avatar);
    }, [userData.avatar]);

    return (
        <Fragment>
            <Breadcrumbs pageTitle={ImpostazioniTitle} title={ImpostazioniTitle} parent=''/>
            <Container fluid>
                <Row>
                    <Card>
                        <CardTitle>
                            <Row>
                                <H3>Risorsa</H3>
                            </Row>
                        </CardTitle>
                        <CardBody>
                            <Row>
                                <Col xl={8} sm={12}>
                                    <FormGroup>
                                        <Label>Nome Risorsa: </Label>
                                        <Input type="text" placeholder="Nome della risorsa"
                                               defaultValue={formValues.nome}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Descrizione Risorsa: </Label>
                                        <Input type="textarea" placeholder="Descrizione della risorsa"
                                               defaultValue={formValues.nome}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Tipo superficie: </Label>
                                        <Input type="select" className=" btn-square">
                                            {nazioniList.map((items, i) => <option
                                                key={i}>{items}</option>)}
                                        </Input>
                                    </FormGroup>
                                    <Row>
                                        <Col xl={12} sm={12}>
                                            <div className="card-wrapper border rounded-3 h-100 checkbox-checked">
                                                <Row>
                                                    <H6 className="sub-title">Impostazioni prenotazione risorsa:</H6>
                                                    <Col xl={6}>
                                                        <FormGroup className="checkbox checkbox-solid-info" check>
                                                            <Input id="prenotabileonline" name="prenotabileonline"
                                                                   type="checkbox"
                                                                   value="si" defaultChecked/>
                                                            <Label htmlFor="prenotabileonline" check> Prenotabile
                                                                online</Label>
                                                        </FormGroup>

                                                        <Label htmlFor="minuti_anticipo_prenotazione" check> Consenti
                                                            prenotazioni con un anticipo di almeno:</Label>
                                                        <InputGroup>
                                                            <Input type="text" placeholder="0"/>
                                                            <InputGroupText
                                                                id="minuti_anticipo_prenotazione">{"minuti"}</InputGroupText>
                                                        </InputGroup>

                                                        <Label htmlFor="giorni_anticipo" check> Consenti prenotazioni
                                                            online da:</Label>
                                                        <InputGroup>
                                                            <Input type="text" placeholder="0"/>
                                                            <InputGroupText
                                                                id="giorni_anticipo">{"giorni"}</InputGroupText>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col xl={6}>
                                                        <FormGroup className="checkbox checkbox-solid-info" check>
                                                            <Input id="cancellabile" name="cancellabile" type="checkbox"
                                                                   value="si" defaultChecked/>
                                                            <Label htmlFor="cancellabile" check> Cancellabile</Label>
                                                        </FormGroup>
                                                        <Label htmlFor="minuti_cancellazione_prenotazione"
                                                               check> Consenti cencellazioni online fino a:</Label>
                                                        <InputGroup>
                                                            <Input type="text" placeholder="0"/>
                                                            <InputGroupText
                                                                id="minuti_cancellazione_prenotazione">{"minuti"}</InputGroupText>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col xl={4} sm={6} className="order-xl-0 order-sm-1 mt-2">
                                    <div className="card-wrapper border rounded-3 h-100 checkbox-checked">
                                        <H6 className="sub-title">Sport associati</H6>
                                        <FormGroup className="checkbox checkbox-primary ps-0 main-icon-checkbox" check>
                                            <UL className='simple-list flex-row checkbox-wrapper'>
                                                {iconCheckboxData.map((data) => (
                                                    <LI key={data.id}>
                                                        <Input className="checkbox-shadow" id={data.id} type="checkbox"
                                                               defaultChecked={data.check === 'checked'}/>
                                                        <Label htmlFor={data.id} check><i
                                                            className={`fa fa-${data.icon}`}></i><span>{data.text}</span></Label>
                                                    </LI>
                                                ))}
                                            </UL>
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} sm={12}>
                                    <div className="mt-2 card-wrapper border rounded-3 h-100 checkbox-checked">
                                        <H6 className="sub-title">Giorni aperti alle prenotazioni</H6>
                                        <Row>
                                            <Col xl={1}>
                                                <FormGroup className="checkbox checkbox-solid-info" check>
                                                    <Input id="lun" name="giorni_apertura" type="checkbox"
                                                           value="lunedi" defaultChecked/>
                                                    <Label htmlFor="lun" check> Lunedì</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={1}>
                                                <FormGroup className="checkbox checkbox-solid-info" check>
                                                    <Input id="mar" name="giorni_apertura" type="checkbox"
                                                           value="martedi" defaultChecked/>
                                                    <Label htmlFor="mar" check> Martedì</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={1}>
                                                <FormGroup className="checkbox checkbox-solid-info" check>
                                                    <Input id="mer" name="giorni_apertura" type="checkbox"
                                                           value="mercoledi" defaultChecked/>
                                                    <Label htmlFor="mer" check> Mercoledì</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={1}>
                                                <FormGroup className="checkbox checkbox-solid-info" check>
                                                    <Input id="gio" name="giorni_apertura" type="checkbox"
                                                           value="giovedi" defaultChecked/>
                                                    <Label htmlFor="gio" check> Giovedì</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={1}>
                                                <FormGroup className="checkbox checkbox-solid-info" check>
                                                    <Input id="ven" name="giorni_apertura" type="checkbox"
                                                           value="venerdi" defaultChecked/>
                                                    <Label htmlFor="ven" check> Venerdì</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={1}>
                                                <FormGroup className="checkbox checkbox-solid-danger" check>
                                                    <Input id="sab" name="giorni_apertura" type="checkbox"
                                                           value="sabato" defaultChecked/>
                                                    <Label htmlFor="sab" check> Sabato</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={1}>
                                                <FormGroup className="checkbox checkbox-solid-danger" check>
                                                    <Input id="dom" name="giorni_apertura" type="checkbox"
                                                           value="domenica" defaultChecked/>
                                                    <Label htmlFor="dom" check> Domenica</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className="text-center mt-3">
                            <Btn color='primary'>{Save}</Btn>
                            <Button className="m-l-5" color="secondary"
                                    onClick={toggleEditing}>{editing ? 'Annulla' : 'Modifica'}</Button>
                        </CardFooter>
                    </Card>
                </Row>
            </Container>
        </Fragment>
    );
};

export default ImpostazioniForm;
