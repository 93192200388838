export const navCustomizerData = [
    {
        id: 2,
        link: "https://support.pixelstrap.com/portal/en/signin",
        image: '2.png',
        title: 'Support'
    },
    {
        id: 3,
        link: "https://docs.pixelstrap.net/react/dunzo/document/",
        image: '3.png',
        title: 'Document'
    },
    {
        id: 4,
        link: "https://landing.pixelstrap.net/react/dunzo/template",
        image: '4.png',
        title: 'Check Features'
    },
    {
        id: 5,
        link: "https://themeforest.net/user/pixelstrap/portfolio",
        image: '5.png',
        title: 'Buy Now'
    },
];

export const layoutTypeData = [
    {type: "ltr", label: "LTR"},
    {type: "rtl", label: "RTL"},
    {type: "box-layout", label: "Box"},
];

export const iconTypes = [
    {type: 'stroke-svg', label: 'Stroke'},
    {type: 'fill-svg', label: 'Fill'}
];

export const lightColorOptions = [
    {name: "color-1", primary: "#307EF3", secondary: "#EBA31D"},
    {name: "color-2", primary: "#33BFBF", secondary: "#FF6150"},
    {name: "color-3", primary: "#006666", secondary: "#FE6A49"},
    {name: "color-4", primary: "#7A70BA", secondary: "#48A3D7"},
    {name: "color-5", primary: "#6271EB", secondary: "#FF8575"},
    {name: "color-6", primary: "#3949ab", secondary: "#4fc3f7"},
];

export const darkColorOptions = [
    {name: "color-1", primary: "#4466f2", secondary: "#1ea6ec"},
    {name: "color-2", primary: "#33BFBF", secondary: "#FF6150"},
    {name: "color-3", primary: "#006666", secondary: "#FE6A49"},
    {name: "color-4", primary: "#7A70BA", secondary: "#48A3D7"},
    {name: "color-5", primary: "#6271EB", secondary: "#FF8575"},
    {name: "color-6", primary: "#3949ab", secondary: "#4fc3f7"},
];

export const mixLayoutData = [
    {value: 'dark-sidebar', headerClass: 'bg-light', sidebarClass: 'bg-dark', bodyClass: 'bg-light'},
    {value: 'dark-only', headerClass: 'bg-dark', sidebarClass: 'bg-dark', bodyClass: 'bg-dark'},
];