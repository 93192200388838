import React, {Fragment} from 'react'
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import {TipologiaRisorsaTitle, NuovoTitle} from "../../../../Utils/Constants";
import {Container, Row} from "reactstrap";
import CardTipologiaRisorsaForm from "../../../../Components/Forms/TipologiaRisorsa/Nuova";


export default function NuovaTipologiarisorse() {
    return (
        <div className='page-body'>
            <Fragment>
                <Breadcrumbs pageTitle={NuovoTitle + " " + TipologiaRisorsaTitle} parent={TipologiaRisorsaTitle}
                             title={NuovoTitle}/>
                <Container fluid>
                    <Row>
                        <CardTipologiaRisorsaForm/>
                    </Row>
                </Container>
            </Fragment>
        </div>
    )
}