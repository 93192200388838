import {ToastContainer} from "react-toastify";
import Routers from "./Routes";
import {useEffect, useState} from "react";
import AuthService from "./Services/auth.service";
import {setUserData} from "./redux-toolkit/reducers/UserDataReducer";
import {useDispatch} from "react-redux";

function App() {
    const dispatch = useDispatch();
    const [isLogged, setIsLogged] = useState(false);
    useEffect(() => {
        const user = AuthService.getCurrentUser().then((response) => {
            dispatch(setUserData(response));
        });
        const is_logged = localStorage.getItem('token_status');
        setIsLogged(false);
        if (is_logged == 'true') {
            setIsLogged(true);
        }
    }, []);
    return (
        <>
            <Routers isLogged={isLogged}/>
            <ToastContainer/>
        </>
    );
}

export default App;
