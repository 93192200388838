import {TableColumn} from "react-data-table-component";

export interface ColonneHtmlElenco {
    id: number;
    nome: string;
}

export const colonneElenco: TableColumn<ColonneHtmlElenco>[] = [
    {
        name: "Id",
        selector: (row) => row.id,
        sortable: true,
    },
    {
        name: "Nome",
        selector: (row) => row.nome,
        sortable: true,
    }
];
