import React, {useEffect, useState} from 'react'
import DataTable from 'react-data-table-component'
import {Card, CardBody, Col} from 'reactstrap'
import CardHeaderSpan from '../../../../../../Utils/CommonComponents/CardHeaderSpan'
import {
    colonneElenco,
    ColonneHtmlElenco
} from '../../../../../../Data/Tables/Colonne/Admin/ElencoMetodipagamento'
import {colonneElencoData} from '../../../../../../Data/Tables/Dati/Admin/ElencoUtentiCentroData'
import {
    CreaNuovo,
    DescrizioneElencoUtenti,
    DescrizioneExtraElencoUtenti,
    HtmlTableTitle
} from '../../../../../../Utils/Constants'
import {HtmlColumnsInterface} from '../../../../../../Types/TableType'
import FilterComponent from '../../../Common/FilterComponent'
import {Btn} from "../../../../../../AbstractElements";
import {Link} from "react-router-dom";

export default function ElencoData() {
    const [filterText, setFilterText] = useState("");
    const [items, setItems] = useState([]); // Dati completi non filtrati
    const [filteredItems, setFilteredItems] = useState([]); // Dati filtrati
  
    useEffect(() => {
      const fetchUserData = async () => {
        try {
          // Presumo che il token sia utilizzato in qualche modo nella chiamata API
          const token = localStorage.getItem("token");
          // La funzione colonneElencoData simula una chiamata async per ottenere i dati
          const currentItems = await colonneElencoData(); // Qui passi il token, se necessario
          setItems(currentItems);
          setFilteredItems(currentItems); // Inizialmente, i dati filtrati sono tutti i dati
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
      fetchUserData();
    }, []);
  
    useEffect(() => {
      // Filtraggio dei dati in base al testo inserito
      const filter = filterText.toLowerCase();
      const filteredData = items.filter((item) =>
        Object.values(item).some((value) =>
          // Aggiungere un controllo di tipo o un'asserzione di tipo qui
          (typeof value === 'string' || typeof value === 'number') && value.toString().toLowerCase().includes(filter)
        )
      );
      setFilteredItems(filteredData);
    }, [filterText, items]);
    
  
    return (
      <Col sm={12}>
        <Card>
          <CardHeaderSpan
            headingClassName="pb-0 card-no-border"
            heading={DescrizioneElencoUtenti}
            span={DescrizioneExtraElencoUtenti}
            bigHeadingClassName="mb-3"
          />
          <CardBody>
            <Link to="nuovo">
              <Btn color="secondary" className="mb-3">
                {CreaNuovo}
              </Btn>
            </Link>
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={() => setFilterText("")}
              filterText={filterText}
            />
            <div className="table-responsive custom-scrollbar">
              <DataTable
                data={filteredItems}
                columns={colonneElenco}
                striped={true}
                pagination
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    );
}
