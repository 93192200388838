import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SportsInterface} from "../../Types/SportsType";

// Stato iniziale come array vuoto di SportsInterface
const initialState: SportsInterface[] = [];

const sportsSlice = createSlice({
    name: "sports",
    initialState: initialState,
    reducers: {
        // Aggiunge uno sport all'array
        addSport: (state, action: PayloadAction<SportsInterface>) => {
            state.push(action.payload);
        },
        // Aggiorna uno sport nell'array
        updateSport: (state, action: PayloadAction<SportsInterface>) => {
            const index = state.findIndex(sport => sport.id === action.payload.id);
            if (index !== -1) {
                state[index] = action.payload;
            }
        },
        // Rimuove uno sport dall'array
        removeSport: (state, action: PayloadAction<number>) => {
            const index = state.findIndex(sport => sport.id === action.payload);
            if (index !== -1) {
                state.splice(index, 1);
            }
        },
        // Setta l'intero array di sport
        setSports: (state, action: PayloadAction<SportsInterface[]>) => {
            return action.payload;
        },
    },
});

export const { addSport, updateSport, removeSport, setSports } = sportsSlice.actions;

export default sportsSlice.reducer;
