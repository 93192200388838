import React, {Fragment} from 'react'
import CardAmbienteForm from "../../../../Components/Forms/Ambiente/Nuovo";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import {CentroSportivoTitle, NuovoTitle} from "../../../../Utils/Constants";
import {Container, Row} from "reactstrap";
import CardCentroSportivoForm from "../../../../Components/Forms/CentroSportivo/Nuovo";


export default function NuovoCentro() {
    return (
        <div className='page-body'>
            <Fragment>
                <Breadcrumbs pageTitle={NuovoTitle + " " + CentroSportivoTitle} parent={CentroSportivoTitle}
                             title={NuovoTitle}/>
                <Container fluid>
                    <Row>
                        <CardCentroSportivoForm/>
                    </Row>
                </Container>
            </Fragment>
        </div>
    )
}