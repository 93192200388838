import {Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormFeedback, Input, Row} from "reactstrap";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import {DunzoProfile, JustifyTabTitle, LooksGood, Previous, Save, Submit} from "../../../../../Utils/Constants";
import React, {useEffect, useRef, useState} from "react";
import {Btn, P} from "../../../../../AbstractElements";
import RisorsaNav from "./RisorsaNav";
import RisorsaNavContent from "./RisorsaNavContent";

import {salvaRisorsa} from "../../../../../Data/Tables/Dati/Admin/SalvaRisorsa";
import {useForm} from "react-hook-form";
import {FormRisorsa} from '../../../../../Types/FormType';
import {SubmitHandler} from 'react-hook-form';
import {toast} from "react-toastify";
import axios from "axios";
import {recuperaRisorsa} from "../../../../../Data/Tables/Dati/Admin/RecuperaRisorsa";
import {useParams} from "react-router-dom";

interface Props {
    resourceId?: number;
}


const API_URL = process.env.REACT_APP_API_URL === undefined ? 'https://sport.artliver.it/' : process.env.REACT_APP_API_URL;


const TabsRisorsa: React.FC<Props> = ({resourceId}) => {
    const [formRisorsa, setFormRisorsa] = useState<FormRisorsa>({
        id: 0,
        nome: '',
        descrizione: '',
        tipologia_id: 0,
        disciplina_id: 0,
        prenotabileonline: false,
        minuti_anticipo_prenotazione: '',
        minuti_cancellazione: '',
        giorni_anticipo: '',
        cancellabile: false,
        mezzora: false,
        incrementomezzora: false,
        orepiene: false,
        giorni_apertura: [],
        slot_orario_composito: [],
        tariffe: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (resourceId) {
                    const response = await axios.get(API_URL + `admin/risorsa/${resourceId}`, {
                        headers: {
                            'Token': localStorage.getItem('token'),
                            'Content-Type': 'application/json'
                        }
                    });
                    setFormRisorsa(response.data);
                }
            } catch (error) {
                console.error('Errore durante il recupero dei dati:', error);
            }
        };

        fetchData(); // Chiamata alla funzione fetchData
    }, [resourceId]);


    const [id, setId] = useState<number | undefined>(resourceId);


    const {register, handleSubmit, formState: {errors}} = useForm<FormRisorsa>();
    const [basicTab, setBasicTab] = useState<string>('1');
    const [validate, setValidate] = useState(false);
    const onSubmit = async () => {
        try {
            if (resourceId) {
                // Aggiorna la risorsa esistente con un metodo PUT
                await axios.put(API_URL + `admin/risorsa/${resourceId}`, formRisorsa, {
                    headers: {
                        'Token': localStorage.getItem('token'),
                        'Content-Type': 'application/json'
                    }
                });
                const RisorsaAggiornataConsuccesso = () => (
                    <div>
                        <span>La risorsa è stata aggiornata con successo!</span><br/>
                    </div>
                );
                toast.success(<RisorsaAggiornataConsuccesso/>, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                // Crea una nuova risorsa con un metodo POST
                let response = await axios.post(API_URL + "admin/risorsa", formRisorsa, {
                    headers: {
                        'Token': localStorage.getItem('token'),
                        'Content-Type': 'application/json'
                    }
                });
                setId(response.data.id);
                const RisorsaSalvataConsuccesso = () => (
                    <div>
                        <span>La risorsa è stata salvata con successo!</span><br/>
                    </div>
                );
                toast.success(<RisorsaSalvataConsuccesso/>, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } catch (error) {
            console.error('Errore durante il salvataggio dei dati:', error);
        }
    };

    return (
        <Col xxl={12}>
            <Card>
                <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" {...register("id")}/>
                    <CommonCardHeader headClass="card-no-border pb-0" title={"Nome Risorsa"}/>
                    <CardBody>
                        <CardHeader
                            className='d-flex justify-content-between align-items-center flex-wrap gap-2 pb-2 p-0'>
                            <P>Naviga i tab per configurare correttamente la risorsa </P>
                            <Btn color='primary'>{Save}</Btn>
                            <RisorsaNav basicTab={basicTab} setBasicTab={setBasicTab}/>

                        </CardHeader>
                        <CardBody className='px-0 pb-0'>
                            <RisorsaNavContent tabId={basicTab} errors={errors} register={register}
                                               validate={validate} formData={formRisorsa} setFormRisorsa={setFormRisorsa}/>
                        </CardBody>
                        <CardFooter className="text-center mt-3">
                            <Btn color='primary'>{Save}</Btn>
                        </CardFooter>
                    </CardBody>
                </Form>
            </Card>
        </Col>
    )
};

export default TabsRisorsa;