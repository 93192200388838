import React, {useState} from 'react'
import {Col, Form, FormGroup, Label, Input, FormFeedback, Row,} from "reactstrap";
import {useForm} from "react-hook-form";
import {
    ChooseFile,
    City,
    DescriptionLabel,
    FirstName,
    LastName,
    LooksGood,
    Email,
    Password,
    SubmitButton,
    Hostname,
    Zip, CreaNuovo, Indietro
} from '../../../../Utils/Constants';
import DropItem from '../../Common/DropItem';
import RadioPayMethod from '../../Common/RadioPayMethod';
import {Btn} from '../../../../AbstractElements';
import {FormData} from '../../../../Types/FormType';
import AuthService from "../../../../Services/auth.service";
import {useNavigate} from "react-router-dom";

export default function CentroSportivoForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState<null | string>(null);
    const [validate, setValidate] = useState(false);
    const {register, handleSubmit, formState: {errors}} = useForm<FormData>();
    const onSubmit = async (data: any) => {
        try {
            setIsLoading(true);
            /*
            const response = await AuthService.register(data.username,data.nome,data.email,data.password,data.password)
            if (response != null) {
                if (response.created === false){
                    window.alert(response.message);
                }else{
                    setValidate(true);
                }
            }*/
        } catch (error) {
            window.alert("ERRORE");
            setValidate(false);
        } finally {
            setIsLoading(false);
        }
    };
    let navigate = useNavigate();

    return (
        <Form className="needs-validation custom-input" onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-3">
                <Col xs={12} md={4}>
                    <FormGroup>
                        <Label>Campo</Label>
                        <input type="text"
                               className={`form-control`}
                               placeholder="placeholder"/>
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <Row className="g-3">
                        <Col xs={9}>
                            <Btn color="primary">{CreaNuovo}</Btn>
                        </Col>
                        <Col xs={3} className="text-end">
                            <Btn color="secondary" onClick={() => navigate(-1)}>{Indietro}</Btn>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {isLoading && <div className="loading-overlay">Attendere...</div>} {/* Finestra di attesa condizionale */}
        </Form>
    )
}
