import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL === undefined ? 'https://sport.artliver.it/' : process.env.REACT_APP_API_URL;


export const colonneElencoDaySlot = () => {

    return axios.get(API_URL + "admin/orario?ds=1", {
        headers: {
            'Token': localStorage.getItem('token')
        }
    }).then((response) => {
            return response.data._embedded.orario;
        })
        .catch((error) => {
            console.error('Errore nella richiesta API:', error);
            throw error; // Rilancia l'errore per gestirlo nel chiamante
        });
};