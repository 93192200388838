import {TableColumn} from "react-data-table-component";
import {Link} from "react-router-dom";

export interface ColonneHtmlElenco {
    id: number;
    nome: string;
    descrizione: string;
    sport: string;
    parametri: string;
}

export const colonneElenco: TableColumn<ColonneHtmlElenco>[] = [
    {
        name: "Id",
        selector: (row) => row.id,
        sortable: true,
    },
    {
        name: "Nome",
        cell: (row) =>  <Link to={`/admin/risorse/nuova/${row.id}`}>{row.nome}</Link>,
        sortable: true,
    },
    {
        name: "Descrizione",
        selector: (row) => row.descrizione,
        sortable: true,
    },
    {
        name: "Sport",
        selector: (row) => row.sport,
        sortable: true,
    },
    {
        name: "Parametri",
        selector: (row) => row.parametri,
        sortable: false,
    }
];
