import React from 'react'
import ElencoContainer from '../../../../Components/Tables/DataTables/DataSource/Admin/Prezzi'

export default function Prezzi() {
    return (
        <div className='page-body'>
            <ElencoContainer/>
        </div>
    )
}
