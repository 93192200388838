export const nazioniList = [
    "--Select--",
    "Germania",
    "Francia",
    "Canada",
    "Usa",
    "Italia",
    "Spanga",
    "Regno Unito",
];
