import React from 'react'
import {Card, CardBody, Col} from 'reactstrap'
import CommonCardHeader from '../../../../Utils/CommonComponents/CommonCardHeader'
import AmbienteForm from './Ambiente'
import CardHeaderSpan from "../../../../Utils/CommonComponents/CardHeaderSpan";

export default function CardAmbienteForm() {

    const titoloForm = "Crea un nuovo ambiente da abilitare al sistema";
    const descrizioneForm = "E' possibile creare un nuovo ambiente per abilitare il sistema su nuovi domini. Va fatto solo all'attivazione di nuovi clienti.";

    return (
        <Col xl={12}>
            <Card className="height-equal">
                <CardHeaderSpan headingClassName="pb-0 card-no-border" heading={titoloForm}
                                span={descrizioneForm}
                                bigHeadingClassName="mb-3"/>
                <CardBody>
                    <AmbienteForm/>
                </CardBody>
            </Card>
        </Col>
    )
}
