import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import {RootState} from "../../../redux-toolkit/store";
import {Card, CardBody, Col, Form, FormGroup, Label, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import CardUtenteForm from "../../../Components/Forms/Utente";
import UserDataForm from "./Form";

export default function AccountUtente() {
    const onEditSubmit = () => {
        alert('funzionalità in sviluppo');
    };
    return (
        <div className='page-body'>
            <UserDataForm></UserDataForm>
        </div>

    )
}
