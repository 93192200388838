import React from 'react'
import ElencoContainer from '../../../../Components/Tables/DataTables/DataSource/Superadmin/Discipline'

export default function Discipline() {
    return (
        <div className='page-body'>
            <ElencoContainer/>
        </div>
    )
}
