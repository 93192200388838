import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TimeSlotType} from "../../Types/TimeSlotType";

const initialState: TimeSlotType[] = [];

const timeslotslice = createSlice({
    name: "timeslot",
    initialState: initialState,
    reducers: {
        addTimeSlot: (state, action: PayloadAction<TimeSlotType>) => {
            state.push(action.payload);
        },
        updateTimeSlot: (state, action: PayloadAction<TimeSlotType>) => {
            const index = state.findIndex(timeslot => timeslot.id === action.payload.id);
            if (index !== -1) {
                state[index] = action.payload;
            }
        },
       removeTimeSlot: (state, action: PayloadAction<number>) => {
            const index = state.findIndex(timeslot => timeslot.id === action.payload);
            if (index !== -1) {
                state.splice(index, 1);
            }
        },
        setTimeSlots: (state, action: PayloadAction<TimeSlotType[]>) => {
            return action.payload;
        },
    },
});

export const { addTimeSlot, updateTimeSlot, removeTimeSlot, setTimeSlots } = timeslotslice.actions;

export default timeslotslice.reducer;
