import React from 'react'
import {Card, CardBody, Col} from 'reactstrap'
import {Link} from 'react-router-dom'
import {H1, Image, P} from '../../../../../AbstractElements'
import {HelloRamirez, ViewProfile} from '../../../../../Utils/Constants'
import {dynamicImage} from '../../../../../Utils'

export default function WelcomeCard() {
    return (
        <Col xs={12} xl={50} className="box-col-6">
            <Card className="welcome-card">
                <CardBody>
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <H1>{HelloRamirez}</H1>
                            <P>{"Welcome back! Let's start from where you left."}</P>
                            <Link className="btn"
                                  to={`${process.env.PUBLIC_URL}/applications/users/user-profile`}>{ViewProfile}</Link>
                        </div>
                        <div className="flex-shrink-0">
                            <Image src={dynamicImage(`dashboard/welcome-1.png`)} alt=""/>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}
