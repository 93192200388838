import React, {useEffect, useState} from 'react'
import {Col} from 'reactstrap'
import {FolderPlus} from 'react-feather'
import {Link} from 'react-router-dom'
import {folderPlusData, leftHeaderData} from '../../../Data/HeaderData/LeftHeaderData'
import SvgIcon from '../../../Utils/CommonComponents/CommonIcons/CommonSvgIcons'
import {LI, UL} from '../../../AbstractElements'
import {HeaderDataType} from "../../../Types/LayoutTypes";
import axios from "axios";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux-toolkit/store";
import UserService from "../../../Services/user.service";
import {setUserData} from "../../../redux-toolkit/reducers/UserDataReducer";

const ProfiloCambiatoConSuccessoToast = () => (
    <div>
        <span>Profilo cambiato con successo!</span><br/>
        <span>Verrà ricaricata la pagina automaticamente</span>
    </div>
);

export default function LeftHeader() {
    const dispatch = useDispatch();
    const [folderOpen, setFolderOpen] = useState(false)
    const folderToggle = () => {
        setFolderOpen(!folderOpen)
    }
    const { profili, profilo_id_ultimoutilizzo, nome_profilo } = useSelector((state: RootState) => state.userData);
    const [elencoProfili, setElencoProfili] = useState<HeaderDataType[]>([]);

    useEffect(() => {
        console.log('Profili = ' + profili)
        const coppie = profili.split(",");
        const oggetto = coppie.reduce((acc: any[], coppia: string) => {
            const [chiave, valore] = coppia.split(":");
            const id = parseInt(chiave);

            if (profilo_id_ultimoutilizzo !== id) {
                acc.push({ id: chiave, text: valore });
            }
            return acc;
        }, []);

        const oggettofinale = [{
            id: 1,
            icon: 'fill-bonus',
            text: 'Profilo: ' + nome_profilo,
            child: oggetto,
        }];
        setElencoProfili(oggettofinale);

    }, [profili, profilo_id_ultimoutilizzo, nome_profilo]);


    const cambiaprofilo = (val: number) => {
        UserService.cambiaProfilo(val).then((response) => {
           if (response.status === true){
               toast.success(<ProfiloCambiatoConSuccessoToast/>, {
                   position: toast.POSITION.TOP_RIGHT,
               });
               dispatch(setUserData(response.dati));
           }else{
               toast.error(response.message, {
                   position: toast.POSITION.TOP_RIGHT
               });
           }
        });
    }

    return (
        <Col xxl={5} xl={4} xs='auto' className="left-header box-col-6 horizontal-wrapper p-0">
            <div className="left-menu-header">
                <UL className="header-left simple-list flex-row">
                    {elencoProfili.map((data) => (
                        <LI className={`onhover-dropdown ${data.id === 4 ? 'p-0' : ''}`} key={data.id}>
                            <span className="f-w-500">
                                <SvgIcon iconId={data.icon}/> {data.text}
                            </span>
                            <UL className="onhover-show-div left-dropdown simple-list">
                                {data.child.map((item) => (
                                    <LI key={item.id}>
                                        <a href="javascript:void(0)"
                                           onClick={() => cambiaprofilo(item.id)}>{item.text}</a>
                                    </LI>
                                ))}
                            </UL>
                        </LI>
                    ))}
                </UL>
            </div>
        </Col>
    )
}
