import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DaySlotType} from "../../Types/DaySlotType";

const initialState: DaySlotType[] = [];

const dayslotslice = createSlice({
    name: "dayslot",
    initialState: initialState,
    reducers: {
        addDaySlot: (state, action: PayloadAction<DaySlotType>) => {
            state.push(action.payload);
        },
        updateDaySlot: (state, action: PayloadAction<DaySlotType>) => {
            const index = state.findIndex(dayslot => dayslot.id === action.payload.id);
            if (index !== -1) {
                state[index] = action.payload;
            }
        },
        removeDaySlot: (state, action: PayloadAction<number>) => {
            const index = state.findIndex(dayslot => dayslot.id === action.payload);
            if (index !== -1) {
                state.splice(index, 1);
            }
        },
        setDaySlots: (state, action: PayloadAction<DaySlotType[]>) => {
            return action.payload;
        },
    },
});

export const {addDaySlot, updateDaySlot, removeDaySlot, setDaySlots} = dayslotslice.actions;

export default dayslotslice.reducer;
