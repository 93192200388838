import ReactDOM from 'react-dom/client';
import './index.scss';
import {GoogleOAuthProvider} from '@react-oauth/google';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from './redux-toolkit/store';
import './i18n';
import React from 'react';


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <GoogleOAuthProvider clientId="911084519820-4g643daj1r4oe14fcarcscdmq8bmu3o1.apps.googleusercontent.com">
        <React.StrictMode>
            <Provider store={store}>
                <App/>
            </Provider>
        </React.StrictMode>
    </GoogleOAuthProvider>,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
