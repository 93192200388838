import React, { Fragment } from "react";
import Breadcrumbs from "../../../../../../CommonElements/Breadcrumbs";
import { ElencoTitle, RisorseTitle } from "../../../../../../Utils/Constants";
import { Container, Row } from "reactstrap";
import ElencoData from "./ElencoData";

export default function ElencoContainer() {
  return (
    <Fragment>
      <Breadcrumbs
        pageTitle={ElencoTitle + " " + RisorseTitle}
        parent={RisorseTitle}
        title={ElencoTitle}
      />
      <Container fluid>
        <Row>
          <ElencoData />
        </Row>
      </Container>
    </Fragment>
  );
}
