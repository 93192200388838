import {configureStore} from "@reduxjs/toolkit";
import layoutReducer from "./reducers/LayoutReducer";
import themeCustomizerReducer from "./reducers/ThemeCustomizerReducer";
import userDataReducer from "./reducers/UserDataReducer";
import sports from "./reducers/SportsReducer";
import timeslots from "./reducers/TimeSlotReducer";
import dayslots from "./reducers/DaySlotReducer";

export const store = configureStore({
    reducer: {
        layout: layoutReducer,
        themeCustomizer: themeCustomizerReducer,
        userData: userDataReducer,
        sports: sports,
        timeslots: timeslots,
        dayslots: dayslots
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
