import React, {useEffect} from 'react';
import {Fragment, useState} from 'react'
import {useSelector} from 'react-redux';
import {SidebarMenuType} from '../../Types/LayoutTypes';
import {Pinned} from '../../Utils/Constants';
import {H6, LI, UL} from '../../AbstractElements';
import {menuList} from '../../Data/SidebarMenuList';
import {menuListSuperadmin} from '../../Data/SidebarMenuListSuperAdmin';
import {menuListAdmin} from '../../Data/SidebarMenuListAdmin';
import SidebarSubMenu from './SidebarSubMenu';
import BackButton from './BackButton';
import {RootState} from '../../redux-toolkit/store';
import {useTranslation} from 'react-i18next';

export default function SidebarNav() {

    const [nomeUtente, setNomeUtente] = useState("");
    const [profilo, setProfilo] = useState("");
    const [activeMenu, setActiveMenu] = useState([]);
    const {pinedMenu} = useSelector((state: RootState) => state.layout);
    const {t} = useTranslation();
    const userData = useSelector((state: RootState) => state.userData);

    const shouldHideMenu = (mainMenu: SidebarMenuType) => {
        return mainMenu.menu.map((data) => data.title).every((tittles) => pinedMenu.includes(tittles as string));
    }

    useEffect(() => {
        setProfilo(userData.nome_profilo);
        setNomeUtente(userData.nome_utente + " " + userData.cognome_utente);
    }, [userData]);

    let realMenuList = menuList;
    if (profilo.toLowerCase() == 'superadmin') {
        realMenuList = menuListSuperadmin;
    }
    if (profilo.toLowerCase() == 'centro') {
        realMenuList = menuListAdmin;
    }
    if (profilo == 'utente') {
        // è il default
    }

    return (
        <UL className='sidebar-links simple-list custom-scrollbar' id='simple-bar'>
            <div className='simplebar-wrapper'>
                <div className='simplebar-mask'>
                    <div className='simplebar-offset'>
                        <div className='simplebar-content-wrapper'>
                            <div className='simplebar-content'>
                                <BackButton/>
                                <LI className={`pin-title sidebar-main-title ${pinedMenu.length > 0 ? "show" : ""} `}>
                                    <div>
                                        <H6>{Pinned}</H6>
                                    </div>
                                </LI>

                                {realMenuList && realMenuList.map((mainMenu, i) => (
                                    <Fragment key={i}>
                                        <LI className={`sidebar-main-title ${shouldHideMenu(mainMenu) ? 'd-none' : ''}`}>
                                            <div>
                                                <H6 className='lan-1'>{t(`${mainMenu.title}`)}</H6>
                                            </div>
                                        </LI>
                                        <SidebarSubMenu menu={mainMenu.menu} activeMenu={activeMenu}
                                                        setActiveMenu={setActiveMenu} level={0}/>
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UL>
    )
}


/*
import React, {useEffect} from 'react';
import {Fragment, useState} from 'react'
import {useSelector} from 'react-redux';
import {SidebarMenuType} from '../../Types/LayoutTypes';
import {Pinned} from '../../Utils/Constants';
import {H6, LI, UL} from '../../AbstractElements';
import {menuList} from '../../Data/SidebarMenuList';
import SidebarSubMenu from './SidebarSubMenu';
import BackButton from './BackButton';
import {RootState} from '../../redux-toolkit/store';
import {useTranslation} from 'react-i18next';

export default function SidebarNav() {
    const [activeMenu, setActiveMenu] = useState([]);
    const {pinedMenu} = useSelector((state: RootState) => state.layout);
    const {t} = useTranslation();
    const shouldHideMenu = (mainMenu: SidebarMenuType) => {
        return mainMenu.menu.map((data) => data.title).every((tittles) => pinedMenu.includes(tittles as string));
    }

    const [idProfilo, setIdProfilo] = useState<number>(0);
    useEffect(() => {

        const datiUtente = localStorage.getItem('dati_utente');
        if (datiUtente != null) {
            setIdProfilo(JSON.parse(datiUtente).profilo_id);
        }
    }, []);
    const currentMenu = idProfilo === 1 ? menuList : menuList;

    return (
        <UL className='sidebar-links simple-list custom-scrollbar' id='simple-bar'>
            <div className='simplebar-wrapper'>
                <div className='simplebar-mask'>
                    <div className='simplebar-offset'>
                        <div className='simplebar-content-wrapper'>
                            <div className='simplebar-content'>
                                <BackButton/>
                                <LI className={`pin-title sidebar-main-title ${pinedMenu.length > 0 ? "show" : ""} `}>
                                    <div>
                                        <H6>{Pinned}</H6>
                                    </div>
                                </LI>
                                {currentMenu && currentMenu.map((mainMenu, i) => (
                                    <Fragment key={i}>
                                        <LI className={`sidebar-main-title ${shouldHideMenu(mainMenu) ? 'd-none' : ''}`}>
                                            <div>
                                                <H6 className='lan-1'>{t(`${mainMenu.title}`)}</H6>
                                            </div>
                                        </LI>
                                        <SidebarSubMenu menu={mainMenu.menu} activeMenu={activeMenu}
                                                        setActiveMenu={setActiveMenu} level={0}/>
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UL>
    )
}
*/