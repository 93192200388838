import {TableColumn} from "react-data-table-component";
import {CustomCellInterFace} from "../../../../Types/TableType";
import {Badges, LI, UL} from "../../../../AbstractElements";
import {Href} from "../../../../Utils/Constants";


const CustomBadge = ({position, color}: CustomCellInterFace) => {
    return (
        <Badges className="rounded-pill" color={color}>{position}</Badges>
    );
};

export interface ColonneHtmlElenco {
    id: number;
    nome: string;
    disciplina_id: number;
}

export const colonneElenco: TableColumn<ColonneHtmlElenco>[] = [
    {
        name: "Id",
        selector: (row) => row.id,
        sortable: true,
    },
    {
        name: "Nome",
        selector: (row) => row.nome,
        sortable: true,
    },
    {
        name: "Disciplina Id associata",
        selector: (row) => row.disciplina_id,
        sortable: true,
    },
    {
        name: "Azioni",
        sortable: true,
        cell: () => (
            <UL className="action simple-list flex-row">
                <LI className="modifica_tipologia_risorsa">
                    <a href={Href}>
                        <i className="icon-grid"/>
                    </a>
                </LI>
                <LI className="elimina_tipologia_risorsa">
                    <a href={Href}>
                        <i className="icon-trash"/>
                    </a>
                </LI>
            </UL>
        ),
    },
];
