import React from 'react'
import ImpostazioniForm from "../../Admin/Criteri/Form";

export default function Criteri() {
    return (
        <div className='page-body'>
            <ImpostazioniForm/>
        </div>
    )
}
