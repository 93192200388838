import {
    Button,
    Card,
    CardBody, CardFooter,
    Col, FormFeedback,
    FormGroup,
    Input,
    InputGroup, InputGroupText,
    Label,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import {Btn, H3, H6, Image, LI, P, UL} from "../../../../../AbstractElements";
import React, {ChangeEvent, useEffect, useState} from "react";
import {colonneElencoDiscipline} from "../../../../../Data/Tables/Dati/Admin/ElencoDiscipline";
import {useDispatch, useSelector} from "react-redux";
import {setSports} from "../../../../../redux-toolkit/reducers/SportsReducer";
import {RootState} from '../../../../../redux-toolkit/store';
import {colonneElencoTimeSlot} from "../../../../../Data/Tables/Dati/Admin/ElencoTimeSlot";
import {setTimeSlots} from "../../../../../redux-toolkit/reducers/TimeSlotReducer";
import {setDaySlots} from "../../../../../redux-toolkit/reducers/DaySlotReducer";
import {colonneElencoDaySlot} from "../../../../../Data/Tables/Dati/Admin/ElencoDaySlot";
import ElencoGiorni from "../../../../../Components/Blocchi/Orari/Settimana";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import {colonneElencoTipologieRisorse} from "../../../../../Data/Tables/Dati/Admin/ElencoTipologieRisorse";
import {TipologieRisorsaInterface} from "../../../../../Types/TipologieRisorsa";
import {LooksGood} from "../../../../../Utils/Constants";
import {FormRisorsa} from "../../../../../Types/FormType";
import {FieldErrors, FieldValues, UseFormRegister} from "react-hook-form";
import {watch} from "fs";
import ListaGiorniSemplice from "../../../../../Components/Blocchi/Giorni";
import ListaSlot from "../../../../../Components/Blocchi/ElencoSlot";

export default function RisorsaNavContent({tabId, errors, validate, register, formData, setFormRisorsa}: {
    tabId: string,
    // handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    errors: FieldErrors<FormRisorsa>,
    validate: boolean,
    register: UseFormRegister<FormRisorsa>,
    formData: FormRisorsa,
    setFormRisorsa: React.Dispatch<React.SetStateAction<FormRisorsa>>;
}) {

    const fetchSuperfici = async (value: string) => {
        try {
            const tipologiaRisorse = await colonneElencoTipologieRisorse(value);
            setTipologieRisorsa(tipologiaRisorse);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    useEffect(() => {
        if (formData.disciplina_id > 0) {
            fetchSuperfici(formData.disciplina_id.toString());

        }
    }, [formData.disciplina_id]);

    /*  const handleSelectSport = (event: ChangeEvent<HTMLInputElement>) => {
          const {name, value, checked} = event.target;
          if (checked) {
              fetchSuperfici(value);
          }
      };*/


    const dispatch = useDispatch();
    const [orepiene, setOrePiene] = useState(true);
    const [tipologieRisorsa, setTipologieRisorsa] = useState<TipologieRisorsaInterface[]>([]);

    // Funzione chiamata quando il checkbox viene selezionato o deselezionato
    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setOrePiene(!event.target.checked); // Aggiorna il valore di flag con lo stato del checkbox
        const {name, value} = event.target;
        //handleChange(event);
    };


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const sportsData = await colonneElencoDiscipline();
                dispatch(setSports(sportsData));
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchUserData();

        const fetchTimeslot = async () => {
            try {
                const tsData = await colonneElencoTimeSlot();
                dispatch(setTimeSlots(tsData));
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchTimeslot();

        const fetchDaySlot = async () => {
            try {
                const dsData = await colonneElencoDaySlot();
                dispatch(setDaySlots(dsData));
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        }
        fetchDaySlot();
    }, []);

    const sports = useSelector((state: RootState) => state.sports);
    const giorni = useSelector((state: RootState) => state.dayslots);

    return (
        <TabContent activeTab={tabId}>
            <TabPane className={`fade ${tabId === "1" ? 'show' : ''}`} tabId="1" key="tab_1">
                <Row>

                    <Col xl={8} sm={12}>
                        <FormGroup>
                            <Label>Nome Risorsa: </Label>
                            <input type="text"
                                   className={`form-control ${errors.nome ? 'is-invalid' : ''} ${validate && !errors.nome ? 'is-valid' : ''}`}
                                   value={formData.nome || ''}
                                   placeholder="Nome della risorsa" {...register("nome", {required: "Inserisci il nome della risorsa"})}
                                   onChange={(e) => setFormRisorsa({...formData, nome: e.target.value})}
                            />
                            {errors.nome && (<FormFeedback>{errors.nome.message}</FormFeedback>)}
                            {validate && !errors.nome && <FormFeedback valid>{LooksGood}</FormFeedback>}
                        </FormGroup>
                        <FormGroup>
                            <Label>Descrizione Risorsa: </Label>
                            <Input className='form-control' type="textarea" {...register("descrizione")}
                                   placeholder="Descrizione della risorsa" defaultValue={formData.descrizione}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Tipo superficie: </Label>
                            <select
                                className={`form-control ${errors.tipologia_id ? 'is-invalid' : ''} ${validate && !errors.tipologia_id ? 'is-valid' : ''}`}
                                {...register("tipologia_id", {required: "La superficie è obbligatoria, seleziona una scelta"})}>
                                {tipologieRisorsa.map((item) => <option
                                    value={item.id} key={item.id}
                                    selected={formData.tipologia_id === item.id}>{item.nome}</option>)}
                            </select>
                            {errors.tipologia_id && (<FormFeedback>{errors.tipologia_id.message}</FormFeedback>)}
                        </FormGroup>
                        <Row>
                            <Col xl={12} sm={12}>
                                <div className="card-wrapper border rounded-3 h-100 checkbox-checked">
                                    <Row>
                                        <H6 className="sub-title">Impostazioni prenotazione
                                            risorsa:</H6>
                                        <Col xl={6}>
                                            <FormGroup className="checkbox checkbox-solid-info" check>
                                                <input className={"form-control"}
                                                       id="prenotabileonline" {...register("prenotabileonline")}
                                                       type="checkbox"
                                                       checked={formData.prenotabileonline || false}
                                                       onChange={(e) => setFormRisorsa({
                                                           ...formData,
                                                           prenotabileonline: Boolean(e.target.checked)
                                                       })}

                                                       defaultChecked/>
                                                <Label htmlFor="prenotabileonline" check> Prenotabile
                                                    online</Label>
                                            </FormGroup>

                                            <Label htmlFor="minuti_anticipo_prenotazione"
                                                   check> Consenti
                                                prenotazioni con un anticipo di almeno:</Label>
                                            <InputGroup>
                                                <input className={"form-control"} type="text"
                                                       placeholder="0"  {...register("minuti_anticipo_prenotazione")}
                                                       value={formData.minuti_anticipo_prenotazione}
                                                       onChange={(e) => setFormRisorsa({
                                                           ...formData,
                                                           minuti_anticipo_prenotazione: e.target.value
                                                       })}
                                                />
                                                <InputGroupText
                                                    id="minuti_anticipo_prenotazione">{"minuti"}</InputGroupText>
                                            </InputGroup>

                                            <Label htmlFor="giorni_anticipo" check> Consenti
                                                prenotazioni
                                                online da:</Label>
                                            <InputGroup>
                                                <input className={"form-control"} type="text"
                                                       placeholder="0" {...register("giorni_anticipo")}
                                                       value={formData.giorni_anticipo}
                                                       onChange={(e) => setFormRisorsa({
                                                           ...formData,
                                                           giorni_anticipo: e.target.value
                                                       })}
                                                />
                                                <InputGroupText
                                                    id="giorni_anticipo">{"giorni"}</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                        <Col xl={6}>
                                            <FormGroup className="checkbox checkbox-solid-info" check>

                                                <input className={"form-control"}
                                                       id="cancellabile" {...register("cancellabile")}
                                                       type="checkbox" checked={formData.cancellabile || false}
                                                       onChange={(e) => setFormRisorsa({
                                                           ...formData,
                                                           cancellabile: e.target.checked
                                                       })}
                                                />
                                                <Label htmlFor="cancellabile"
                                                       check> Cancellabile</Label>
                                            </FormGroup>
                                            <Label htmlFor="minuti_cancellazione_prenotazione"
                                                   check> Consenti cencellazioni online fino a:</Label>
                                            <InputGroup>
                                                <input className={"form-control"} type="text"
                                                       placeholder="0" {...register("minuti_cancellazione")}
                                                       value={formData.minuti_cancellazione}
                                                       onChange={(e) => setFormRisorsa({
                                                           ...formData,
                                                           minuti_cancellazione: e.target.value
                                                       })}
                                                />
                                                <InputGroupText
                                                    id="minuti_cancellazione_prenotazione">{"minuti"}</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col xl={4} sm={6} className="order-xl-0 order-sm-1 mt-2">
                        <div className="card-wrapper border rounded-3 h-100 checkbox-checked">
                            <H6 className="sub-title">Sport della risorsa</H6>
                            <FormGroup className="checkbox checkbox-primary ps-0 main-icon-checkbox"
                                       check>
                                <UL className='simple-list flex-row checkbox-wrapper'>
                                    {sports.map((data) => (
                                        <LI key={"sport_" + data.id}>

                                            <input
                                                className="form-control checkbox-shadow form-check-input"
                                                id={"sport_" + data.nome}
                                                {...register("disciplina_id")}
                                                type="radio"
                                                value={data.id}
                                                checked={formData.disciplina_id === data.id}
                                                onChange={(e) => setFormRisorsa({
                                                    ...formData,
                                                    disciplina_id: parseInt(e.target.value)
                                                })} // Aggiorna lo stato formRisorsa
                                            />
                                            <Label htmlFor={data.nome} check><span>{data.nome}</span></Label>

                                        </LI>
                                    ))}
                                </UL>
                            </FormGroup>
                        </div>
                    </Col>
                </Row>
            </TabPane>
            <TabPane className={`fade ${tabId === "2" ? 'show' : ''}`} tabId="2" key="tab_2">
                <Row>
                    <Col xl={4}>
                        <Card className='height-equal'>
                            <CommonCardHeader headClass='pb-0'
                                              title="Ora prenotabile a partire dal minuto 30 dell'ora?"/>
                            <CardBody>
                                <FormGroup className="checkbox checkbox-solid-info" check>
                                    <input className={"form-control"}
                                           id="orapiena" {...register("orepiene", {value: (orepiene)})}
                                           type="checkbox" checked={formData.orepiene}
                                           defaultChecked={!orepiene} onChange={handleCheckboxChange}/>
                                    <Label htmlFor="orapiena" check> Si, voglio che gli orari di gioco partano dalla
                                        mezz'ora anzichè dall'inizio dell'ora</Label>
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={4}>
                        <Card className='height-equal'>
                            <CommonCardHeader headClass='pb-0'
                                              title="Abilita incremento prenotazione di mezz'ora"/>
                            <CardBody>
                                <FormGroup className="checkbox checkbox-solid-primary" check>
                                    <input className={"form-control"}
                                           id="incrementomezzora" {...register("incrementomezzora")} type="checkbox"
                                           value="1" checked={formData.incrementomezzora}/>
                                    <Label htmlFor="incrementomezzora" check> Se selezionato sarà possibile prenotare
                                        anche per 1 ora e 30 minuti e 2 ore 30 minuti.</Label>
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={4}>
                        <Card className='height-equal'>
                            <CommonCardHeader headClass='pb-0'
                                              title="Permetti prenotazioni di solo mezz'ora"/>
                            <CardBody>
                                <FormGroup className="checkbox checkbox-solid-danger" check>
                                    <input className={"form-control"} id="mezzora" {...register("mezzora")}
                                           type="checkbox" checked={formData.mezzora}
                                           value="1"/>
                                    <Label htmlFor="mezzora" check> Se selezionato sarà possibile effettuare
                                        prenotazioni di mezz'ora (solitamente mai utilizzato dai centri
                                        sportivi)</Label>
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={12}>
                        <ElencoGiorni orepiene={orepiene} register={register} formRisorsa={formData}
                                      setFormRisorsa={setFormRisorsa}/>
                    </Col>
                </Row>
            </TabPane>
            <TabPane className={`fade ${tabId === "3" ? 'show' : ''}`} tabId="3" key="tab_3">
                {formData.tariffe.map((tariffa) =>
                    <Row>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xl={4} sm={12}>
                                        <h6>{tariffa.nome} - {tariffa.descrizione}</h6>
                                    </Col>
                                    <Col xl={2} sm={12}>
                                        <FormGroup>
                                            <Label>Prezzo (Singolo utente) </Label>
                                            <Input type="text" placeholder="0,00"
                                                   value={tariffa.prezzo_singolo_utente}/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={2} sm={12}>
                                        <FormGroup>
                                            <Label>Prezzo (Totale della risorsa) </Label>
                                            <Input type="text" placeholder="0,00" value={tariffa.prezzo_totale}/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={2} sm={12}>
                                        <FormGroup>
                                            <Label>Valida dal giorno</Label>
                                            <Input type="date" placeholder="0,00" value={tariffa.valida_dal}/>
                                        </FormGroup>
                                    </Col>

                                    <Col xl={2} sm={12}>
                                        <FormGroup>
                                            <Label>Valida fino al giorno </Label>
                                            <Input type="date" placeholder="0,00" value={tariffa.valida_al}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                <Col xl={4} sm={12}>
                                    <h6>Giorni e orari di applicabilità della tariffa</h6>
                                </Col>
                                <Col xl={8} sm={12}>
                                    {tariffa.personalizzazioni_orarie.map((personalizzazione_oraria) =>
                                    <Row>
                                        <Col xl={1} sm={12}>
                                            <FormGroup>
                                                <Label>ID </Label>
                                                <Input type="text" placeholder="ID della personalizzazione" value={personalizzazione_oraria.id_raggruppamento}/>
                                            </FormGroup>
                                        </Col>
                                        <Col xl={2} sm={12}>
                                            <FormGroup>
                                                <Label>Nome </Label>
                                                <Input type="text" placeholder="Nome della personalizzazione" value={personalizzazione_oraria.descrizione}/>
                                            </FormGroup>
                                        </Col>
                                        <Col xl={9} sm={12}>
                                            <Row>
                                                <Col xl={12} sm={12}>
                                                    <FormGroup>
                                                        <Label>Giorni </Label>
                                                        <ListaGiorniSemplice register={register} formRisorsa={formData} setFormRisorsa={setFormRisorsa} />

                                                     </FormGroup>
                                                </Col>
                                                <Col xl={12} sm={12}>
                                                    <FormGroup>
                                                        <Label>Slot Orari </Label>
                                                        <ListaSlot orepiene={orepiene} register={register} formRisorsa={formData} setFormRisorsa={setFormRisorsa} />

                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    )}
                                </Col>
                            </Row>
                            </CardBody>
                        </Card>

                    </Row>
                )}

                <Row>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xl={4} sm={12}>
                                    <h6>Tariffa Estiva (da intendersi a persona)</h6>
                                </Col>
                                <Col xl={2} sm={12}>
                                    <FormGroup>
                                        <Label>Prezzo </Label>
                                        <Input type="text" placeholder="10,00"/>
                                    </FormGroup>
                                </Col>
                                <Col xl={2} sm={12}>
                                    <FormGroup>
                                        <Label>Supplemento luce </Label>
                                        <Input type="text" placeholder="2,00"/>
                                    </FormGroup>
                                </Col>
                                <Col xl={2} sm={12}>
                                    <FormGroup>
                                        <Label>Orari supplemento luce</Label>

                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                </Row>
                <Row>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xl={4} sm={12}>
                                    <h6>Tariffa Invernale (da intendersi a persona)</h6>
                                </Col>
                                <Col xl={2} sm={12}>
                                    <FormGroup>
                                        <Label>Prezzo </Label>
                                        <Input type="text" placeholder="10,00"/>
                                    </FormGroup>
                                </Col>
                                <Col xl={2} sm={12}>
                                    <FormGroup>
                                        <Label>Supplemento luce </Label>
                                        <Input type="text" placeholder="2,00"/>
                                    </FormGroup>
                                </Col>
                                <Col xl={2} sm={12}>
                                    <FormGroup>
                                        <Label>Orari supplemento luce</Label>

                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                </Row>
            </TabPane>
        </TabContent>
    )
}
