import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Image} from "../../AbstractElements";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux-toolkit/store";
import {setSideBarToggle} from "../../redux-toolkit/reducers/LayoutReducer";
import SvgIcon from "../../Utils/CommonComponents/CommonIcons/CommonSvgIcons";
import {dynamicImage} from "../../Utils";

export default function LogoWrapper() {
    const dispatch = useDispatch();
    const layoutState = useSelector((state: RootState) => state.layout);
    const handleSidebarToggle = () => {
        dispatch(setSideBarToggle(!layoutState.sideBarToggle));
    };
    const [base64Logo, setBase64Logo] = useState<string>();
    const userData = useSelector((state: RootState) => state.userData);
    useEffect(() => {
        setBase64Logo("data:image/jpeg;base64,"+userData.logo);
    }, [userData.logo]);
    return (
        <div className='logo-wrapper'>
            <Link to={`${process.env.PUBLIC_URL}/pages/sample_page`}>
                <Image className='img-fluid' src={base64Logo} alt='logo'/>
            </Link>
            <div className='toggle-sidebar' onClick={handleSidebarToggle}>
                <SvgIcon className='sidebar-toggle' iconId='toggle-icon'/>
            </div>
        </div>
    )
}
