import {TableColumn} from "react-data-table-component";
import {CustomCellInterFace} from "../../../../Types/TableType";
import {Badges, LI, UL} from "../../../../AbstractElements";
import {Href} from "../../../../Utils/Constants";


const CustomBadge = ({position, color}: CustomCellInterFace) => {
    return (
        <Badges className="rounded-pill" color={color}>{position}</Badges>
    );
};

export interface ColonneHtmlElencoAmbienti {
    id: number;
    hostname: string;
    hostname1: string;
    hostname3: string;
    api_key: string;
    api_secret: string;
    centro: string;
}

export const colonneElencoAmbienti: TableColumn<ColonneHtmlElencoAmbienti>[] = [
    {
        name: "Id",
        selector: (row) => row.id,
        sortable: true,
    },
    {
        name: "Hostname primario",
        selector: (row) => row.hostname,
        sortable: true,
    },
    {
        name: "Hostname 2",
        selector: (row) => row.hostname1,
        sortable: true,
    },
    {
        name: "Hostname 3",
        selector: (row) => row.hostname3,
        sortable: true,
    },
    {
        name: "Api Key",
        selector: (row) => row.api_key,
        sortable: true,
    },
    {
        name: "Api Secret",
        selector: (row) => row.api_secret,
        sortable: true,
    },
    {
        name: "Centro sportivo associato",
        selector: (row) => row.centro,
        sortable: true,
    },
    {
        name: "Azioni",
        sortable: true,
        cell: () => (
            <UL className="action simple-list flex-row">
                <LI className="modifica_ambiente">
                    <a href={Href}>
                        <i className="icon-text"/>
                    </a>
                </LI>
                <LI className="elimina_ambiente">
                    <a href={Href}>
                        <i className="icon-trash"/>
                    </a>
                </LI>
            </UL>
        ),
    },
];
