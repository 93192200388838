import axios from "axios";
import {toast} from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL === undefined ? 'https://sport.artliver.it/' : process.env.REACT_APP_API_URL;

const getPublicContent = () => {
    return axios.get(API_URL + "all");
};

const getUserBoard = () => {
    return axios.get(API_URL + "user");
};

const getModeratorBoard = () => {
    return axios.get(API_URL + "mod");
};

const getAdminBoard = () => {
    return axios.get(API_URL + "admin");
};


const creaUtente = (username, nome, cognome, email, password, password_confirm) => {
    return axios.post(API_URL + "utente", {
            username: username,
            nome: nome,
            cognome: cognome,
            email: email,
            password: password,
            // password_confirm: password_confirm,
        },
        {
            headers: {
                'Token': localStorage.getItem("token")
            }
        }
    )
        .then((response) => {
            console.log("############################# SONO QUIIIIIII");
            console.log(response.data)
            return response.data;
        });
};
const cambiaProfilo = (nuovoProfiloId) => {
    return axios.patch(API_URL + "user/profilo/" + nuovoProfiloId, null, {
        headers: {
            'Token': localStorage.getItem('token')
        }
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        // Gestisci eventuali errori qui
        toast.error('Errore in cambio profilo: ' + error, {
            position: toast.POSITION.TOP_RIGHT
        });
    });
};
const salvaDatiUtente = (id,formValues) => {
    const formData = new FormData();
    Object.entries(formValues).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return axios.patch(API_URL + "user/info/" + id,
        formData,
        {
            headers: {
                'Token': localStorage.getItem("token"),
                'Content-Type': 'application/json'
            }
        }
    )
    .then((response) => {
        console.log(response.data)
        return response.data;
    });
};

const UserService = {
    getPublicContent,
    getUserBoard,
    getModeratorBoard,
    getAdminBoard,
    creaUtente,
    cambiaProfilo,
    salvaDatiUtente
}

export default UserService;
