import React from 'react'
import {Card, CardBody, Col} from 'reactstrap'
import TipologiaRisorsaForm from './TipologiaRisorsa'
import CardHeaderSpan from "../../../../Utils/CommonComponents/CardHeaderSpan";

export default function CardTipologiaRisorsaForm() {

    const titoloForm = "Tipologia di risorsa a sistema";
    const descrizioneForm = "Elenco delle tipologie di risorse presenti nel sistema.";

    return (
        <Col xl={12}>
            <Card className="height-equal">
                <CardHeaderSpan headingClassName="pb-0 card-no-border" heading={titoloForm}
                                span={descrizioneForm}
                                bigHeadingClassName="mb-3"/>
                <CardBody>
                    <TipologiaRisorsaForm/>
                </CardBody>
            </Card>
        </Col>
    )
}
