import React, {ChangeEvent, Fragment, useState} from 'react'
import {Col, FormGroup, Input, Label, Row} from 'reactstrap'
import {useSelector} from "react-redux";
import {RootState} from "../../../redux-toolkit/store";
import {UseFormRegister} from "react-hook-form";
import {LI, UL} from "../../../AbstractElements";
import {FormRisorsa} from "../../../Types/FormType";

interface Props {
    register: UseFormRegister<FormRisorsa>,
    formRisorsa: FormRisorsa;
    setFormRisorsa: React.Dispatch<React.SetStateAction<FormRisorsa>>;
}

export const ListaGiorniSemplice: React.FC<Props> = ({register, formRisorsa, setFormRisorsa}) => {
    const dayslots = useSelector((state: RootState) => state.dayslots);

    return (
        <>

               <Row>
                   <Col xl={12}>
                    <div className="card-wrapper border rounded-3 checkbox-checked mb-2">
                        <FormGroup className="checkbox checkbox-primary ps-0 main-icon-checkbox"
                                   check>
                            <UL className='simple-list flex-row checkbox-wrapper '>
                                {dayslots.map((data) => (
                                        <LI key={data.id}>
                                            <input
                                                className={"checkbox-shadow form-control form-check-input selettore_giorno_tariffa"}
                                                id={data.id.toString()} {...register("tariffe")}
                                                type="checkbox" value={data.id}
                                                //checked={(formRisorsa.slot_orario_composito || []).includes(idprefix + "_" + data.id)}
                                                //onChange={handleSingleCheckboxChange(idprefix, data.id)}
                                            />
                                            <Label htmlFor={data.id.toString()}
                                                   check><span>{data.giorno}</span></Label>
                                        </LI>
                                ))}
                            </UL>
                        </FormGroup>
                    </div>
                </Col>
               </Row>
        </>
    )
}
export default ListaGiorniSemplice;