import React from 'react'
import {Card, CardBody, Col} from 'reactstrap'
import CommonCardHeader from '../../../../Utils/CommonComponents/CommonCardHeader'
import CentroSportivoForm from './Disciplina'
import CardHeaderSpan from "../../../../Utils/CommonComponents/CardHeaderSpan";

export default function CardDisciplinaForm() {

    const titoloForm = "Discipline di sistema";
    const descrizioneForm = "Creare discipline, una tantum. Se in futuro ce ne saranno altre, inserirle qua.";

    return (
        <Col xl={12}>
            <Card className="height-equal">
                <CardHeaderSpan headingClassName="pb-0 card-no-border" heading={titoloForm}
                                span={descrizioneForm}
                                bigHeadingClassName="mb-3"/>
                <CardBody>
                    <CentroSportivoForm/>
                </CardBody>
            </Card>
        </Col>
    )
}
