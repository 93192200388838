import {SidebarMenuType} from "../Types/LayoutTypes";

export const menuListAdmin: SidebarMenuType[] = [
    {
        title: 'Gestione',
        menucontent: 'Pagine di gestione admin centro',
        menu: [
            {
                id: 1,
                title: 'Prenotazioni',
                url: `${process.env.PUBLIC_URL}/admin/prenotazioni`,
                icon: 'calendar',
                type: 'link'
            },
            {
                id: 2,
                title: 'Utenti',
                url: `${process.env.PUBLIC_URL}/admin/utenti`,
                icon: 'board',
                type: 'link'
            },
            {
                id: 3,
                title: 'Risorse',
                url: `${process.env.PUBLIC_URL}/admin/risorse`,
                icon: 'bookmark',
                type: 'link'
            },
            {
                id: 4,
                title: 'Prezzi',
                url: `${process.env.PUBLIC_URL}/admin/prezzi`,
                icon: 'to-do',
                type: 'link'
            },
            {
                id: 5,
                title: 'Impostazioni',
                url: `${process.env.PUBLIC_URL}/admin/criteri`,
                icon: 'knowledgebase',
                type: 'link'
            },
            {
                id: 6,
                title: 'Metodi di pagamento',
                url: `${process.env.PUBLIC_URL}/admin/metodipagamento`,
                icon: 'faq',
                type: 'link'
            },
        ],
    },
];