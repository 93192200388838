import React from 'react'
import SamplePageContainer from '../../../Components/Pages/SamplePage'

export default function SamplePage() {
    return (
        <div className='page-body'>
            <SamplePageContainer/>
        </div>
    )
}
