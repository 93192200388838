import React, {useEffect, useState} from 'react';
import {GoogleLogin, googleLogout, useGoogleLogin} from '@react-oauth/google';
import axios from 'axios';
import AuthService from "../../Services/auth.service";
import {toast} from "react-toastify";
import {setUserData} from "../../redux-toolkit/reducers/UserDataReducer";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {GoogleLink} from "../../Utils/Constants";
import FeatherIconCom from "../../Utils/CommonComponents/CommonIcons/FeatherIconCom";
import Icon from "react-multi-date-picker/components/icon";
import {FaGoogle} from "react-icons/fa";

function GoogleButton() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            let accessToken = codeResponse.access_token;
            if (accessToken.length > 0) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        //Chiamare mie api
                        AuthService.loginSocial('google', JSON.stringify(res.data)).then(
                            (response) => {
                                if (response.loggedIn) {
                                    toast.success("Login Success...!");
                                    localStorage.setItem("token_status", JSON.stringify(true));
                                    const user = AuthService.getCurrentUser().then((response) => {
                                        dispatch(setUserData(response));
                                        navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
                                    });
                                    // localStorage.setItem("login", JSON.stringify(true));
                                } else {
                                    // console.log(response)
                                    window.alert(response.message);
                                }
                            },
                            (error) => {
                                window.alert("Errore in login");
                            }
                        );
                    })
                    .catch((err) => console.log(err));
            }
        },
        onError: (error) => {
            console.log('Cazzo di budda:', error)
        }
    });

    return (

        <button className='btn btn-light' onClick={login}><FaGoogle
            className='txt-google-plus'/>&nbsp;&nbsp;{GoogleLink}</button>

    );
}

export default GoogleButton;