import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {Image, LI, P, UL} from '../../../AbstractElements'
import {Admin, HelenWalter, Href, Logout} from '../../../Utils/Constants'
import {profileHeaderData} from '../../../Data/HeaderData/RightHeaderData'
import FeatherIconCom from '../../../Utils/CommonComponents/CommonIcons/FeatherIconCom'
import {dynamicImage} from '../../../Utils';
import AuthService from "../../../Services/auth.service";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux-toolkit/store";

export default function ProfileHeader() {
    const logout = () => {
        localStorage.clear();
    }

    const [nomeUtente, setNomeUtente] = useState("");
    const [profilo, setProfilo] = useState("");
    const userData = useSelector((state: RootState) => state.userData);
    const [base64Avatar, setBase64Avatar] = useState<string>();
    useEffect(() => {
        setProfilo(userData.nome_profilo);
        setNomeUtente(userData.nome_utente + " " + userData.cognome_utente);
        setBase64Avatar("data:image/jpeg;base64,"+userData.avatar);
    }, [userData]);

    return (
        <LI className="profile-nav onhover-dropdown p-0">
            <div className="d-flex align-items-center profile-media">
                <Image className="b-r-10 img-40" src={base64Avatar} alt=""/>
                <div className="flex-grow-1"><span>{nomeUtente}</span>
                    <P className="mb-0">{profilo} <i className="middle fa fa-angle-down"></i></P>
                </div>
            </div>
            <UL className="profile-dropdown onhover-show-div simple-list">
                {profileHeaderData.map((data) => (
                    <LI key={data.id}>
                        <Link to={data.url}>
                            <FeatherIconCom iconName={data.icon}/>
                            <span>{data.text}</span></Link>
                    </LI>
                ))}
                <LI>
                    <Link onClick={logout} to={`${process.env.PUBLIC_URL}/login`}>
                        <FeatherIconCom iconName={"LogIn"}/>
                        <span>{Logout}</span>
                    </Link>
                </LI>
            </UL>
        </LI>
    )
}
