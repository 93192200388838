import React, {Fragment, useEffect, useState} from 'react'
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import {NuovaTitle, RisorsaTitle} from "../../../../Utils/Constants";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import CardRisorsaForm from "../../../../Components/Forms/Risorsa/Nuovo";
import JustifyTab from "./TabsRisorsa";
import TabsRisorsa from "./TabsRisorsa";
import CardHeaderSpan from "../../../../Utils/CommonComponents/CardHeaderSpan";
import RisorsaForm from "../../../../Components/Forms/Risorsa/Nuovo/Risorsa";
import {colonneElencoData} from "../../../../Data/Tables/Dati/Admin/ElencoRisorse";
import {colonneElencoDiscipline} from "../../../../Data/Tables/Dati/Admin/ElencoDiscipline";
import {useParams} from "react-router-dom";

const titoloForm = "Crea una nuova risorsa";
const descrizioneForm = "Per risorsa si intende l'impianto sportivo soggetto a possibili prenotazioni";

export default function NuovaRisorsa() {
    const [elenco_sport, setSports] = useState([]); // Dati completi non filtrati
    const { id } = useParams();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const sports = await colonneElencoDiscipline();
                setSports(sports);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchUserData();
    }, []);


    return (
        <div className='page-body'>
            <Fragment>
                <Breadcrumbs pageTitle={NuovaTitle + " " + RisorsaTitle} parent={RisorsaTitle}
                             title={NuovaTitle}/>
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <Card className="height-equal">
                                <CardHeaderSpan headingClassName="pb-0 card-no-border" heading={titoloForm}
                                                span={descrizioneForm}
                                                bigHeadingClassName="mb-3"/>
                                <CardBody>
                                    <TabsRisorsa resourceId={id ? parseInt(id) : undefined}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        </div>
    )
}