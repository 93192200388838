import {createSlice} from "@reduxjs/toolkit";
import { UserDataInterface } from "../../Types/UserDataType";

const initialState: UserDataInterface = {
    data_registrazione: "",
    nascita_data: "",
    nascita_luogo: "",
    email: "",
    approvato: "",
    avatar: "",
    _raw_userData: undefined,
    ambiente_id: 0,
    api_key: "",
    api_secret: "",
    app_mobile_id: "",
    codice_fiscale: "",
    codice_nazione_partita_iva: "",
    cognome_utente: "", // Dovrebbe essere una stringa, non un numero
    data: "",
    denominazione_fiscale: "",
    fiscale_cap: 0,
    fiscale_citta: "",
    fiscale_civico: "",
    fiscale_email: "",
    fiscale_indirizzo: "",
    fiscale_pec: "",
    fiscale_telefono: "",
    hostname: "",
    hostname1: "",
    hostname3: "",
    id: 0,
    logo: "",
    nome_profilo: "",
    nome_utente: "",
    nome_visibile: "",
    partita_iva: "",
    profili: "",
    profilo_id: 0,
    profilo_id_ultimoutilizzo: 0,
    sessione_valida: 0,
    utente_id: 0,
    visibile_cap: 0,
    visibile_citta: "",
    visibile_civico: "",
    visibile_email: "",
    visibile_indirizzo: "",
    visibile_riferimento: "",
    visibile_telefono: ""
};

const UserDataSlice = createSlice({
    name: "userData",
    initialState: initialState,
    reducers: {
        setUserData: (state, action) => {
            state._raw_userData = action.payload;
            Object.assign(state, action.payload);
        },
    },
});

export const { setUserData} = UserDataSlice.actions;

export default UserDataSlice.reducer;
