import SamplePage from "../Pages/Pages/SamplePage";
import Default from "../Pages/Dashboards/Default";

import Ambienti from "../Pages/Superadmin/Ambienti/Elenco";
import NuovoAmbiente from "../Pages/Superadmin/Ambienti/Nuovo";

import Centri from "../Pages/Superadmin/Centri/Elenco";
import NuovoCentro from "../Pages/Superadmin/Centri/Nuovo";

import Profili from "../Pages/Superadmin/Profili/Elenco";
import NuovoProfilo from "../Pages/Superadmin/Profili/Nuovo";

import Discipline from "../Pages/Superadmin/Discipline/Elenco";
import NuovaDisciplina from "../Pages/Superadmin/Discipline/Nuova";

import SAUtenti from "../Pages/Superadmin/Utenti/Elenco";
import NuovoSAUtente from "../Pages/Superadmin/Utenti/Nuovo";

import Tipologiarisorse from "../Pages/Superadmin/Tipologiarisorse/Elenco";
import NuovaTipologiarisorse from "../Pages/Superadmin/Tipologiarisorse/Nuova";

import Impersonifica from "../Pages/Superadmin/Impersonifica";
import Log from "../Pages/Superadmin/Log";

import Utenti from "../Pages/Admin/Utenti/Elenco";
import NuovoAdmUtente from "../Pages/Admin/Utenti/Nuovo";

import Prenotazioni from "../Pages/Admin/Prenotazioni/Elenco";
import NuovaPrenotazione from "../Pages/Admin/Prenotazioni/Nuova";

import Risorse from "../Pages/Admin/Risorse/Elenco";
import NuovaRisorsa from "../Pages/Admin/Risorse/Nuova";

import Impostazioni from "../Pages/Admin/Criteri";

import Metodipagamento from "../Pages/Admin/Metodipagamento/Elenco";
import NuovoMetodoPagamento from "../Pages/Admin/Metodipagamento/Nuovo";

import Prezzi from "../Pages/Admin/Prezzi/Elenco";
import NuovoPrezzo from "../Pages/Admin/Prezzi/Nuovo";

import Preferenze from "../Pages/User/Preferenze";
import Elencoprenotazioni from "../Pages/User/Prenotazioni/Elenco";
import Nuovaprenotazione from "../Pages/User/Prenotazioni/Nuova";
import NuovoUtente from "../Pages/Pages/Utenti";

import AccountUtente from "../Pages/User/Account";


export const routes = [
    {path: `${process.env.PUBLIC_URL}/pages/sample_page`, element: <SamplePage/>},
    {path: `${process.env.PUBLIC_URL}/dashboard/default`, element: <Default/>},
    {path: `${process.env.PUBLIC_URL}/pages/utenti`, element: <NuovoUtente/>},
    {path: `${process.env.PUBLIC_URL}/pages/risorse`, element: <Risorse/>},


    {path: `${process.env.PUBLIC_URL}/superadmin/ambienti`, element: <Ambienti/>},
    {path: `${process.env.PUBLIC_URL}/superadmin/ambienti/nuovo`, element: <NuovoAmbiente/>},

    {path: `${process.env.PUBLIC_URL}/superadmin/centri`, element: <Centri/>},
    {path: `${process.env.PUBLIC_URL}/superadmin/centri/nuovo`, element: <NuovoCentro/>},

    {path: `${process.env.PUBLIC_URL}/superadmin/profili`, element: <Profili/>},
    {path: `${process.env.PUBLIC_URL}/superadmin/profili/nuovo`, element: <NuovoProfilo/>},

    {path: `${process.env.PUBLIC_URL}/superadmin/utenti`, element: <SAUtenti/>},
    {path: `${process.env.PUBLIC_URL}/superadmin/utenti/nuovo`, element: <NuovoSAUtente/>},

    {path: `${process.env.PUBLIC_URL}/superadmin/discipline`, element: <Discipline/>},
    {path: `${process.env.PUBLIC_URL}/superadmin/discipline/nuova`, element: <NuovaDisciplina/>},

    {path: `${process.env.PUBLIC_URL}/superadmin/tipologiarisorse`, element: <Tipologiarisorse/>},
    {path: `${process.env.PUBLIC_URL}/superadmin/tipologiarisorse/nuova`, element: <NuovaTipologiarisorse/>},

    {path: `${process.env.PUBLIC_URL}/superadmin/impersonifica`, element: <Impersonifica/>},
    {path: `${process.env.PUBLIC_URL}/superadmin/log`, element: <Log/>},

    {path: `${process.env.PUBLIC_URL}/admin/utenti`, element: <Utenti/>},
    {path: `${process.env.PUBLIC_URL}/admin/utenti/nuovo`, element: <NuovoAdmUtente/>},

    {path: `${process.env.PUBLIC_URL}/admin/prenotazioni`, element: <Prenotazioni/>},
    {path: `${process.env.PUBLIC_URL}/admin/prenotazioni/nuova`, element: <NuovaPrenotazione/>},

    {path: `${process.env.PUBLIC_URL}/admin/risorse`, element: <Risorse/>},
    {path: `${process.env.PUBLIC_URL}/admin/risorse/nuova/:id?`, element: <NuovaRisorsa/>},

    {path: `${process.env.PUBLIC_URL}/admin/criteri`, element: <Impostazioni/>},

    {path: `${process.env.PUBLIC_URL}/admin/prezzi`, element: <Prezzi/>},
    {path: `${process.env.PUBLIC_URL}/admin/prezzi`, element: <NuovoPrezzo/>},

    {path: `${process.env.PUBLIC_URL}/admin/metodipagamento`, element: <Metodipagamento/>},
    {path: `${process.env.PUBLIC_URL}/admin/metodipagamento/nuovo`, element: <NuovoMetodoPagamento/>},



    {path: `${process.env.PUBLIC_URL}/user/prenotazioni/nuova`, element: <Nuovaprenotazione/>},
    {path: `${process.env.PUBLIC_URL}/user/prenotazioni/elenco`, element: <Elencoprenotazioni/>},
    {path: `${process.env.PUBLIC_URL}/user/preferenze`, element: <Preferenze/>},
    {path: `${process.env.PUBLIC_URL}/user/account`, element: <AccountUtente/>},


]