import {SidebarMenuType} from "../Types/LayoutTypes";

export const menuListSuperadmin: SidebarMenuType[] = [
    {
        title: 'Ambienti',
        menucontent: 'Ambienti di superadmin',
        menu: [
            {
                id: 1,
                title: 'Ambienti',
                url: `${process.env.PUBLIC_URL}/superadmin/ambienti`,
                icon: 'animation',
                type: 'link'
            },
            {
                id: 2,
                title: 'Centri sportivi',
                url: `${process.env.PUBLIC_URL}/superadmin/centri`,
                icon: 'file',
                type: 'link'
            }
        ]
    },
    {
        title: 'Gestione utenti',
        menucontent: 'Gestione utenti',
        menu: [
            {
                id: 3,
                title: 'Profili di sistema',
                url: `${process.env.PUBLIC_URL}/superadmin/profili`,
                icon: 'layout',
                type: 'link'
            },
            {
                id: 4,
                title: 'Utenti',
                url: `${process.env.PUBLIC_URL}/superadmin/utenti`,
                icon: 'board',
                type: 'link'
            }
        ]
    },
    {
        title: 'Gestione risorse',
        menucontent: 'Gestione utenti',
        menu: [
            {
                id: 5,
                title: 'Discipline',
                url: `${process.env.PUBLIC_URL}/superadmin/discipline`,
                icon: 'bookmark',
                type: 'link'
            },
            {
                id: 6,
                title: 'Tipologia risorse',
                url: `${process.env.PUBLIC_URL}/superadmin/tipologiarisorse`,
                icon: 'to-do',
                type: 'link'
            }
        ],
    },
    {
        title: 'Amministrazione',
        menucontent: 'Voci di amministrazione',
        menu: [
            {
                id: 7,
                title: 'Impersonifica',
                url: `${process.env.PUBLIC_URL}/superadmin/impersonifica`,
                icon: 'knowledgebase',
                type: 'link'
            },
            {
                id: 8,
                title: 'Log',
                url: `${process.env.PUBLIC_URL}/superadmin/log`,
                icon: 'faq',
                type: 'link'
            }
        ],
    }
];